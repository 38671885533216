<template>
  <div class="message-page">
    <vuestic-widget :loading="installFetching">
      <template v-if="installDidFetch">
        <template slot="header">
          <div class="w-100">
            <div class="header-row d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <span class="title">Inbox: </span>
                <div class="ml-2" style="width: 450px;">
                  <multiselect v-model="selectedInstall" :options="optionInstalls" :multiple="false"
                    :close-on-select="true" :allow-empty="false" :preserve-search="true" placeholder="Select Install"
                    label="label" track-by="id" :preselect-first="false" :show-labels="false" @input="onChangeInstall">
                    <template slot="singleLabel" slot-scope="props">
                      <div class="d-flex justify-content-between">
                        <span class="option__label">{{
                          props.option.label
                        }}</span><span class="option__number_message" v-if="props.option.number_of_messages">{{
  props.option.number_of_messages }}</span>
                      </div>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="d-flex justify-content-between">
                        <span class="option__label">{{
                          props.option.label
                        }}</span><span class="option__number_message" v-if="props.option.number_of_messages">{{
  props.option.number_of_messages }}</span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div v-if="installDepartmentEnabled" class="d-flex align-items-center">
                <span class="title">Department: </span>
                <div class="ml-2" style="width: 300px;">
                  <multiselect v-model="selectedDepartment" :options="departmentList" :multiple="false"
                    :close-on-select="true" :allow-empty="false" :preserve-search="true" placeholder="Select Department"
                    label="label" track-by="label" :preselect-first="false" :show-labels="false"
                    @input="onDepartmentChange">
                  </multiselect>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <search-input name="searchInput" v-model="searchFilter" @input="refreshInbox" />
                <button v-if="selectedInstall" v-b-tooltip.hover title="New Contact" class="btn btn-primary btn-sm ml-2"
                  @click="onNewContactButton()">
                  <span><i class="fa fa-plus"></i></span>
                </button>
              </div>
            </div>
          </div>
        </template>
        <div class="sms-wrapper border-top">
          <div class="sms-wrapper__left border-right">
            <div class="sub-header border-bottom filter-wrapper">
              <multiselect v-model="statusFilter" :options="filerOptions" :multiple="false" :close-on-select="true"
                :searchable="false" :allow-empty="false" :preserve-search="true" placeholder="Select Status" label="label"
                track-by="label" :preselect-first="false" :show-labels="false" :max-height="600" @input="onChangeFilter">
              </multiselect>
            </div>
            <div class="contact-list-wrapper">
              <div class="contact-item" v-for="(contact, index) in contacts" :key="index" v-bind:class="{
                selected: selectedContact && contact.id == selectedContact.id,
                unread: contact.status == 'unread',
              }" @click="selectContact(contact, true)">
                <div class="profile-avatar-wrapper">
                  <div class="profile-avatar">
                    {{ contact | avatar }}
                    <div v-if="contact.source == 5" class="google-icon">
                      <img data-v-2c10668c="" src="@/assets/images/integrations/google-message.webp" class="integraion-logo">
                    </div>
                  </div>
                </div>
                <div class="profile-content-wrapper">
                  <div class="contact-item__header">
                    <div>
                      <span v-if="contact.full_name">{{ contact.full_name }}</span>
                      <span v-else>{{ contact.number | phone }}</span>
                      <span v-if="!isValid(contact.number)" class="pl-2 alert-span" v-b-tooltip.hover
                        title="Phone Number is invalid"><i class="text-danger fa fa-exclamation-circle"></i></span>
                    </div>
                    <span><small>{{
                      contactTimeStamp(contact) | shorttime(timezone)
                    }}</small></span>
                  </div>
                  <div v-if="contact.last_message">
                    <span class="message">{{
                      contact.last_message.message | prune
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="text-center mt-2" v-if="loadingContacts">
                <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="#3578c6" />
              </div>
              <div class="list-blank" v-if="!loadingContacts && !contacts.length">
                You don't have any contact
              </div>
            </div>
          </div>
          <div class="sms-wrapper__right" v-if="selectedContact">
            <div class="sub-header border-bottom">
              <div class="contact-div">
                <span class="name">{{ selectedContact.full_name }}</span>
                <span class="phone">{{ selectedContact.number | phone }}</span>
              </div>
              <div style="min-width: 400px;" class="d-flex" v-if="userList && userList.length > 1">
                <multiselect v-model="newAgent" :options="userList" :multiple="false" :close-on-select="true"
                  :allow-empty="false" :preserve-search="true" placeholder="Assign Conversation" label="label"
                  track-by="label" :preselect-first="false" :show-labels="false" @input="onAssignmentChange">
                </multiselect>
                <button v-if="newAgent" class="btn btn-sm btn-danger ml-2" v-b-tooltip.hover title="Remove Assignment"
                  @click="onRemoveAssignment()">
                  <span class="text-white"><i class="fa fa-close"></i></span>
                </button>
              </div>
              <div class="action-wrapper">
                <ul>
                  <li>
                    <button :disabled="processingContact" class="btn btn-sm btn-primary mr-2" v-b-tooltip.hover
                      title="Refresh" @click="refreshConversation">
                      <span class="text-white"><i class="fa fa-refresh"></i></span>
                    </button>
                  </li>
                  <li v-if="selectedContact.status != 'unread'">
                    <button :disabled="processingContact" class="btn btn-sm btn-primary mr-2" v-b-tooltip.hover
                      title="Mark as Unread" @click="updateConversationStatus('unread')">
                      <span class="text-white"><i class="fa fa-bookmark"></i></span>
                    </button>
                  </li>
                  <li v-if="selectedContact.status === 'unread'">
                    <button :disabled="processingContact" class="btn btn-sm btn-primary mr-2" v-b-tooltip.hover
                      title="Mark as Open" @click="updateConversationStatus('')">
                      <span class="text-white"><i class="fa fa-book"></i></span>
                    </button>
                  </li>
                  <li v-if="selectedContact.status !== 'deleted'">
                    <button :disabled="processingContact" class="btn btn-sm btn-primary mr-2" v-b-tooltip.hover
                      title="Mark as Closed" @click="updateConversationStatus('deleted')">
                      <span class="text-white"><i class="fa fa-check"></i></span>
                    </button>
                  </li>
                  <li v-if="selectedContact.status === 'deleted'">
                    <button :disabled="processingContact" class="btn btn-sm btn-primary mr-2" v-b-tooltip.hover
                      title="Mark as Open" @click="updateConversationStatus('')">
                      <span class="text-white"><i class="fa fa-paper-plane"></i></span>
                    </button>
                  </li>
                  <li v-if="!editContactOpened">
                    <a href="javascript:;" @click="toggleContactDetail()">
                      <span><i class="fa fa-ellipsis-v"></i></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="selectedContact" class="d-flex justify-content-center align-items-center top-from-text">
              <span v-b-tooltip.hover :title="topFromText(selectedContact)" class="text-primary">{{
                topFromText(selectedContact) | prune(90) }}
              </span>
              <span v-if="selectedContact.website" v-b-tooltip.hover title="Copy Full URL"
                class="text-primary d-inline-block ml-3" v-clipboard="selectedContact.website"
                v-clipboard:success="onCopyUrl" v-clipboard:error="onCopyUrlError">
                <i class="fa fa-clone font-weight-bold"></i>
              </span>
            </div>
            <div class="sms-content" :class="{
              'has-top-text': selectedContact && selectedContact.website,
              closed: statusFilter === 'deleted',
            }">
              <div class="text-center mt-5" v-if="loadingConversations">
                <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="#3578c6" />
              </div>
              <div class="sms-item" v-for="(item, index) in conversationSorted" :key="index"
                :class="{ from: !item.outgoing && !item.automation_id, broadcast: item.is_broadcast, schedule: item.is_scheduled, email: item.source_type == 3, 'link-opened': item.is_link_opened }">
                <div
                  v-if="!conversationSorted[index - 1] || conversationSorted[index - 1] && item.created_at.slice(0, 10) !== conversationSorted[index - 1].created_at.slice(0, 10)"
                  class="sms-time-container">
                  <span class="sms-time">{{ getSmsDate(item) }}</span>
                </div>
                <div class="profile-avatar-wrapper"
                  :class="{ from: !item.outgoing && !item.automation_id, to: item.outgoing || item.automation_id }">
                  <div v-b-tooltip.hover :title="selectedContact.full_name" class="profile-avatar" v-if="!item.outgoing">
                    {{ selectedContact | avatar }}
                    <div v-if="selectedContact.source == 5" class="google-icon">
                      <img data-v-2c10668c="" src="@/assets/images/integrations/google-message.webp" class="integraion-logo">
                    </div>
                  </div>

                  <div v-b-tooltip.hover :title="item.sender.full_name || businessOwner.full_name" class="profile-avatar"
                    v-if="item.outgoing"><span v-if="!item.sender.avatar && !businessOwner.avatar">{{
                      (item.sender.first_name ? item.sender : businessOwner) | avatar }}</span>
                    <img v-else :src="item.sender.avatar || businessOwner.avatar" class="avatar-img" />
                  </div>
                </div>

                <div v-if="item.medias && item.medias.length">
                  <div v-for="(media, key) in item.medias" :key="key" class="mb-3">
                    <div v-if="media.path" class="conversation-wrapper media-wrapper" :class="{
                      'no-padding': checkMediaType(media, ['image']),
                    }" v-b-tooltip.hover v-b-tooltip.top :title="formatMessageTimestamp(item)">
                      <a href="javascript:;" @click="onOpenMedia(media)">
                        <img v-if="checkMediaType(media, ['image'])" :src="media.path" />
                        <span v-else class="file-wrapper"><i class="fa fa-file"></i>&nbsp; <b>{{ media.filename
                        }}</b></span>
                      </a>
                    </div>
                  </div>
                </div>

                <template v-if="item.type === 'call'">
                  <div class="conversation-wrapper" v-b-tooltip.hover v-b-tooltip.top
                    :title="formatMessageTimestamp(item)">
                    <div class="message font-weight-bold"><i class="fa fa-phone"></i>
                      {{ item.automation_id ? 'Automation Call' : 'Inbound Call' }} <span v-if="item.status"
                        class="text-capitalize font-weight-normal"> - {{
                          item.status }}</span></div>
                    <template v-if="item.recording">
                      <audio v-if="item.recording.url" ref="player" controls :src="item.recording.url">
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                      <span v-else>{{ item.recording.duration }} Secs</span>
                    </template>
                  </div>
                </template>
                <template v-else-if="item.type === 'callScheduled'">
                  <div class="conversation-wrapper" v-b-tooltip.hover v-b-tooltip.top
                    :title="formatMessageTimestamp(item)">
                    <div class="message font-weight-bold mb-3"><i class="fa fa-phone"></i> Scheduled Call</span></div>
                    <span v-if="item.date" class="d-block schedule_info">
                      <i class="fa fa-clock-o"></i> {{ item.date | longtime(timezone) }}
                      <u class="cancel-link pl-2" @click="onCancelScheduleCall(item)">Cancel</u>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div v-if="item.message" class="conversation-wrapper">
                    <span v-if="item.is_automation" class="automation-icon" v-b-tooltip.hover title="Automation SMS"><i
                        class="fa fa-magic"></i></span>
                    <div v-b-tooltip.hover v-b-tooltip.top :title="formatMessageTimestamp(item)">
                      <span class="message" v-html="formatMessage(item)" v-linkified></span>
                      <span v-if="item.scheduled_at" class="d-block schedule_info">
                        <i class="fa fa-clock-o"></i> {{ item.scheduled_at | longtime(timezone) }}
                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="comment-slash" role="img"
                          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                          class="svg-inline--fa fa-comment-slash fa-w-20">
                          <path fill="currentColor"
                            d="M320 80c114.7 0 208 71.8 208 160 0 25.3-7.9 49.1-21.5 70.4l37.9 29.6c20.1-29.6 31.6-63.7 31.6-100 0-114.9-114.6-208-256-208-48.2 0-93 11-131.5 29.8l43 33.6C258.4 85.6 288.3 80 320 80zm0 320c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C133.7 314.1 112 282.2 112 240c0-16.6 3.3-32.7 9.5-47.8L82.8 162c-12 24.1-18.8 50.4-18.8 78 0 47.6 19.9 91.2 52.9 126.3-14.9 39.4-45.9 72.8-46.4 73.2-6.6 7-8.4 17.2-4.6 26S78.4 480 88 480c61.5 0 110-25.7 139.1-46.3C256 442.8 287.2 448 320 448c37.5 0 73-6.7 105.1-18.5l-46.2-36.2c-18.7 4.3-38.5 6.7-58.9 6.7zm314 71L481.6 351.8l-6.8-5.3L36 3.5C29.1-2 19-.9 13.5 6l-10 12.5C-2 25.4-.9 35.5 6 41l598 467.5c6.9 5.5 17 4.4 22.5-2.5l10-12.5c5.5-6.9 4.4-17-2.5-22.5z"
                            class=""></path>
                        </svg>
                        Stop <b>{{ item.stop_on_response ? 'ON' : 'OFF' }}</b>
                        <u class="cancel-link pl-2" @click="onCancelSchedule(item)">Cancel</u>
                      </span>
                    </div>
                  </div>
                  <div v-if="item.audio_url" class="conversation-wrapper">
                    <div class="message font-weight-bold"><i class="fa fa-microphone"></i> VoiceRecord</div>
                    <audio ref="player" controls :src="item.audio_url">
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                  </div>
                </template>
                <div class="sms-seen-container">
                  <span v-if="!item.outgoing" class="sms-seen" :class="{ 'delivered': item.status === 'delivered' }">
                    {{ selectedContact.full_name ? selectedContact.full_name + ' - ' : '' }}{{ getSmsTime(item) }}
                  </span>
                  <span v-else class="sms-seen"
                    :class="{ 'delivered': item.status === 'delivered', 'failed': item.status === 'failed' }">
                    {{ (item.sender.first_name ? item.sender.full_name : businessOwner.full_name) }} - {{ getSmsTime(item)
                    }}
                    <span v-if="!item.scheduled_at">
                      <i v-if="item.status === 'sent'" class="fa fa-check" v-b-tooltip.hover
                        title="Message has been sent"></i>
                      <i v-else-if="item.status === 'delivered'" class="fa fa-check-circle-o" v-b-tooltip.hover
                        title="Message has been delivered"></i>
                      <template v-else-if="item.status === 'failed'">
                        <template v-if="item.error_message">
                          <i @click="openError(item)" class="fa fa-info-circle mx-2" v-b-tooltip.hover
                            :title="item.error_message"></i>
                        </template>
                        <i @click="resendMessage(item)" class="fa fa-refresh" v-b-tooltip.hover
                          title="Failed to send. Please try again"></i>
                      </template>
                    </span>
                  </span>
                </div>
              </div>
              <div v-if="!conversations.length && !loadingConversations" class="text-center">
                You don't have any conversations
              </div>
            </div>
            <div v-if="statusFilter !== 'deleted' && selectedContact" class="sms-send-wrapper border-top m-0">
              <div v-if="selectedContact.opt_out">
                <div class="alert err-message font-weight-bold alert-danger title text-center mb-2" role="alert">
                  This contact is manually opted out, you must manually opt them back in to send them a message.
                </div>
              </div>
              <div v-else-if="selectedContact.status === 'blocked'">
                <div class="alert err-message font-weight-bold alert-danger title text-center mb-2" role="alert">
                  This contact is blocked, you must manually un-block them back to send them a message.
                </div>
              </div>
              <div v-else>
                <b-tabs small card class="text-tabs" :class="{ 'show-header': enableEmail }" v-model="activeTabIndex">
                  <b-tab title="SMS" @click="scrollMessagesToBottom()" active>
                    <TextareaEmojiPicker v-on:send="onSendMessage" v-model="newMessage" :loading="loadingSend"
                      :disabled="smsLimit || selectedContact.opt_out" :focused="true" :convert="true"
                      :enabledMms="enabledMms" :enabledTemplate="true" :enabledSaveReply="true" :enabledSchedule="true"
                      :scheduleActive="scheduleData.active" :enableIcs="true" :enable-link="true" :enable-test="true"
                      :enable-ai="true" :selected-install="selectedInstall ? selectedInstall.id : null"
                      ref="textareaEmoji" :contact="selectedContact" :auto-generate="true"
                      @onOpenSaveReply="onOpenModalSaveReply" @onOpenContactInfo="onOpenContactInfo"
                      @onSchedule="onSchedule" @onSendICS="onSendICS" />
                  </b-tab>
                  <b-tab v-if="enableEmail" title="Email" @click="scrollMessagesToBottom()">
                    <TextEditorPicker
                      v-on:send="sendEmail"
                      v-model="newEmail"
                      :loading="loadingSend"
                      :enabledEmoji="true"
                      :enabledTemplate="true"
                      :convert="true"
                      :height="140"
                      :enableIcs="true"
                      :contact="selectedContact"
                      ref="texteditor"
                      :scheduleActive="scheduleData.active"
                      :emailIcsFile="emailIcsFile"
                      :enable-test="true"
                      :enabled-schedule="true"
                      :enable-ai="true"
                      @onOpenContactInfo="onOpenContactInfo"
                      @onSchedule="onSchedule"
                      @onEmailSendICS="onEmailSendICS"
                    />
                  </b-tab>
                </b-tabs>
              </div>
            </div>
            <div v-if="statusFilter === 'deleted' && selectedContact"
              class="w-100 text-center p-2 border-top closed-span">
              <span>This conversation was closed on
                {{ selectedContact.archived_at | longtime(timezone) }}.</span>
            </div>
          </div>
          <div class="sms-contact-detail border-left" v-if="editContactOpened && selectedContact">
            <div class="sub-header border-bottom">
              <span><b>Contact</b></span>
              <a href="javascript:;" @click="toggleContactDetail()"><span><i class="fa fa-times"></i></span></a>
            </div>
            <div class="contact-detail-wrapper">
              <b-tabs small card :class="{ 'show-header': showFiles }">
                <b-tab title="Contact Info" class="contact-info-wrapper">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(updateContact)">
                      <div class="contact-edit-wrapper d-flex">
                        <input type="text" placeholder="First Name" name="FirstName" v-model="contactDetail.first_name"
                          @blur="handleContactInputBlur($event, 'first_name')" />
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'first_name'"
                          slot="loading" :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <div class="contact-edit-wrapper d-flex">
                        <input type="text" placeholder="Last Name" name="LastName" v-model="contactDetail.last_name"
                          @blur="handleContactInputBlur($event, 'last_name')" />
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'last_name'"
                          slot="loading" :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <span class="detail-span">{{
                        selectedContact.created_at | longtime(timezone)
                      }}</span>
                      <div class="contact-edit-wrapper d-flex">
                        <input type="email" placeholder="Email" name="Email" v-model="contactDetail.email"
                          @blur="handleContactInputBlur($event, 'email')" />
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'email'" slot="loading"
                          :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <div class="contact-edit-wrapper d-flex">
                        <div class="w-100">
                          <BirthdayInput v-model="contactDetail.bday" @input="handleContactInputBlur($event, 'bday')" />
                        </div>
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'bday'" slot="loading"
                          :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <div class="contact-edit-wrapper d-flex">
                        <div class="w-100">
                          <BirthdayInput v-model="contactDetail.anniversary"
                            @input="handleContactInputBlur($event, 'anniversary')" label="Anniversary" />
                        </div>
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'anniversary'"
                          slot="loading" :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <div class="contact-edit-wrapper d-flex">
                        <input type="text" placeholder="City" name="City" v-model="contactDetail.city"
                          @blur="handleContactInputBlur($event, 'city')" />
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'city'" slot="loading"
                          :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <CustomFields v-model="contactDetail.custom_fields" @change="() => updateContact()" :hide-bar="true"
                        :margin-bottom="0" :full-width="true" />
                      <div class="contact-edit-wrapper d-flex">
                        <textarea rows="3" placeholder="Note" name="Notes" v-model="contactDetail.note"
                          @blur="handleContactInputBlur($event, 'note')"></textarea>
                        <atom-spinner v-if="loadingUpdateContact && loadingUpdateContactField == 'note'" slot="loading"
                          :animation-duration="1500" :size="20" color="#3578c6" />
                      </div>
                      <div class="form-group tag-wrapper mt-4">
                        <div class="input-group">
                          <label class="control-label">Tags</label>
                          <div>
                            <span class="add-tag-btn mr-2 noselect" href="javascript:;" @click="onAddTag()"><i
                                class="fa fa-plus"></i>Add Tag</span>
                            <template v-if="!loadingDeleteTag">
                              <span class="tag noselect" @click="onDeleteTag(item)"
                                v-for="(item, key) in contactDetail.tags" :key="key">
                                {{ item.name }}
                                <i class="fa fa-close"></i>
                              </span>
                            </template>
                            <atom-spinner v-else slot="loading" :animation-duration="1500" :size="14" color="#3578c6" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group tag-wrapper mt-4">
                        <div class="input-group">
                          <label class="control-label">Automations In Process</label>
                          <div>
                            <span class="add-tag-btn mr-2 noselect" href="javascript:;" @click="onAddAutomationClick()"><i
                                class="fa fa-plus"></i>Add Automation</span>
                            <template v-if="!loadingAutomation">
                              <span class="tag noselect" @click="onDeleteAutomation(item)"
                                v-for="(item, key) in contactDetail.automations" :key="key" v-b-tooltip.hover
                                :title="getAutomationTooltip(item)">
                                {{ item.name }} <span v-if="item.process_cnt > 1">( {{ item.process_cnt }} )</span>
                                <i class="fa fa-close"></i>
                              </span>
                            </template>
                            <atom-spinner v-else slot="loading" :animation-duration="1500" :size="14" color="#3578c6" />
                          </div>
                        </div>
                      </div>

                      <div class="mt-2">
                        <div class="d-flex w-100">
                          <div class="col-md-6 text-center pt-1">
                            <a href="javascript:;" v-if="!selectedContact.twilio_opt_out" class="btn btn-sm" :class="{
                              'btn-success': selectedContact.opt_out,
                              'btn-danger': !selectedContact.opt_out,
                            }" @click="onOptContact()"><i :class="{
  'fa fa-spinner fa-spin': processingContactOpt,
  'fa fa-ban': !processingContactOpt,
}"></i>
                              {{
                                selectedContact.opt_out ? "Opt In" : "Opt Out"
                              }}</a>
                            <a href="javascript:;" v-else class="btn btn-sm btn-success" v-b-tooltip.hover
                              title="We can't Opt In this contact as customer unsubscribed on his/her side."><i
                                class="fa fa-ban"></i> Opt In</a>
                          </div>
                          <div class="col-md-6 text-center pt-1">
                            <a href="javascript:;" class="btn btn-sm btn-danger" @click="onDeleteContact()"><i :class="{
                              'fa fa-spinner fa-spin': processingContact,
                              'fa fa-trash': !processingContact,
                            }"></i>
                              Delete</a>
                          </div>
                        </div>
                        <div class="d-flex w-100 justify-content-center mt-2">
                          <div class="col-md-6 text-center pt-1">
                            <a href="javascript:;" class="btn btn-sm btn-danger"
                              v-if="selectedContact.status !== 'blocked'" @click="isOpenBlockDelete = true"
                              v-b-tooltip.hover title="This will remove and block all requests from the contact."><i
                                :class="{
                                  'fa fa-spinner fa-spin': processingContact,
                                  'fa fa-times': !processingContact,
                                }"></i>
                              Block</a>
                            <a href="javascript:;" class="btn btn-sm btn-primary" v-else @click="handleUnblockContact"
                              v-b-tooltip.hover title="This will accept all requests from the contact."><i :class="{
                                'fa fa-spinner fa-spin': processingContact,
                                'fa fa-times': !processingContact,
                              }"></i>
                              UnBlock</a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>
                </b-tab>
                <b-tab v-if="showFiles" title="Files">
                  <div class="text-center my-5" v-if="loadingUpdateContact">
                    <atom-spinner slot="loading" :animation-duration="1500" :size="40" color="#3578c6" />
                  </div>
                  <div v-else class="contact-files-wrapper">
                    <template v-if="contactFiles.length">
                      <div class="contact-file-item" v-for="(media, key) in contactFiles" :key="key">
                        <a href="javascript:;" @click="onOpenMedia(media)">
                          <span v-if="checkMediaType(media, ['image'])">
                            <img class="preview" :src="media.path" />
                            <b><span> {{ media.filename }}</span></b>
                          </span>
                          <span v-else class="file-wrapper"><i class="fa fa-file"></i> <b>{{ media.filename }}</b></span>
                        </a>
                      </div>
                    </template>
                    <span v-else class="d-block text-center">No Files</span>
                  </div>
                </b-tab>
                <b-tab v-if="showTasks" title="Tasks">
                  <task-tab :contact-id="selectedContact.id" :timezone="timezoneScheduled ? timezoneScheduled.value: null" />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </template>
    </vuestic-widget>

    <vuestic-modal :isOpen="isOpenModalTags" @cancel="onCloseModalTag" :cancelShown="false" :okShown="false">
      <span slot="title">Tag Contact</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(onTagContact)">
          <div class="row">
            <div class="col-md-12">
              <tag-selector v-model="tagsSelected"></tag-selector>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="contactProcessing || invalid">
                <atom-spinner v-if="contactProcessing" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" />
                <span v-else>Assign</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalCreate" @ok="closeModalCreate" @cancel="closeModalCreate" okText="Create"
      :cancelShown="false" :okShown="false">
      <span slot="title">Create a Contact</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(saveNewContact)">
          <div class="row">
            <div class="col-md-6">
              <text-input name="First Name" v-model="newContactForm.first_name" label="First Name" />
            </div>
            <div class="col-md-6">
              <text-input name="Last Name" v-model="newContactForm.last_name" label="Last Name" />
            </div>
            <div class="col-md-6">
              <phone-number-input name="Phone Number" v-model="newContactForm.number" label="Phone Number" />
            </div>
            <div class="col-md-6">
              <text-input type="email" name="Email" v-model="newContactForm.email" label="Email" />
            </div>
            <div class="col-md-6">
              <BirthdayInput v-model="newContactForm.bday" />
            </div>
            <div class="col-md-6">
              <BirthdayInput v-model="newContactForm.anniversary" label="Anniversary" />
            </div>
            <div class="col-md-6">
              <text-input name="City" v-model="newContactForm.city" label="City" />
            </div>
            <div class="col-md-6 mt-4">
              <div class="form-group">
                <div class="input-group">
                  <textarea v-model="newContactForm.note" name="Notes" class="form-control w-100" maxlength="299"
                    rows="3"></textarea>
                  <label class="control-label" for="Message">Notes</label>
                  <i class="bar"></i>
                </div>
              </div>
              <SMSLength :text="newContactForm.note" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-4">
              <tag-selector v-model="tagsSelected"></tag-selector>
            </div>
            <div class="col-md-12 mb-4">
              <label class="input-label has-tool-tip">Automation <a v-b-tooltip.hover
                  title="Run selected automations when contact is being created."
                  class="fa fa-question-circle tooltip-icon" /></label>
              <multiselect :show-labels="false" v-model="selectedAutomation" :options="automations" :multiple="false"
                :preserve-search="true" :allowEmpty="true" placeholder="Select Automation" label="name" track-by="id">
              </multiselect>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="custom-checkbox-wrapper d-inline-block">
                <label class="checkbox-label">
                  <input type="checkbox" id="confirm-checkbox" v-model="isConfirmed" />
                  <span class="checkbox-custom"></span>
                </label>
              </div>
              <span class="d-inline-block ml-2" style="cursor: pointer" @click="isConfirmed = !isConfirmed">We have
                permission to contact this person via text
                message.</span>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="loadingNewContact || invalid || !isConfirmed">
                <atom-spinner v-if="loadingNewContact" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" />
                <span v-else>Create</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalDelete" :okDisabled="processingContact" @ok="handleDeleteContact"
      @cancel="isOpenModalDelete = false" okText="Delete" okClass="btn btn-danger" cancelText="Cancel">
      <span slot="title" class="text-danger">Delete this Contact?</span>
      <p>Are you sure you want to delete this contact? This can not be undone.</p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenBlockDelete" :okDisabled="processingContact" @ok="handleBlockContact"
      @cancel="isOpenBlockDelete = false" okText="Block" okClass="btn btn-danger" cancelText="Cancel">
      <span slot="title" class="text-danger"><b>Block this Contact?</b></span>
      <p>Are you sure you want to block this contact? All requests from this phone number and ip will be blocked.</p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalSaveReply" @cancel="onCloseModalSaveReply" :cancelShown="false" :okShown="false"
      :large="true">
      <span slot="title">Save As A Message Template</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="templateForm">
        <form @submit.prevent="handleSubmit(onSaveReply)" class="templateCreateForm">
          <div class="row">
            <div class="col-sm-12">
              <text-input name="Name" v-model="newTemplate.name" label="Name" validate="required" />
            </div>
            <div class="col-md-12 mt-4">
              <validation-provider rules="required" v-slot="{ }" name="Message">
                <TextareaEmojiPicker v-model="newTemplate.message" :loading="loading" :focused="true" :rows="7"
                  :enabledMms="false" ref="textareaEmojiTemplate" :showSendButton="false" :hasHoverEffect="false" />
              </validation-provider>
            </div>
            <div class="col-md-12 mt-4">
              <DropzoneUpload @updated="dropzoneUpdated" @processing="onDropzoneProcessing" ref="dropzoneUpload">
              </DropzoneUpload>
            </div>
          </div>
          <div slot="footer">
            <div class="col-md-12 mt-3 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="invalid || processingTemplate || dropzoneProcessing"
                style="min-width: 80px;">
                <atom-spinner v-if="processingTemplate" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Save Template</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="scheduleMessageOpened" @cancel="onCloseModalScheduleMessage" :cancelShown="false"
      :okShown="false" :large="true">
      <span slot="title" class="text-primary">Schedule Messsage For Later</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="templateForm">
        <form @submit.prevent="handleSubmit(onSubmitSchedule)" class="templateCreateForm">
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectScheduleDay('today')"
                    :class="{ 'btn-primary': scheduleData.daySelect === 'today' }">Today</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectScheduleDay('tomorrow')"
                    :class="{ 'btn-primary': scheduleData.daySelect === 'tomorrow' }">Tomorrow</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectScheduleDay('after_tomorrow')"
                    :class="{ 'btn-primary': scheduleData.daySelect === 'after_tomorrow' }">{{ afterTomorrow }}</button>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="form-group">
                    <div class="input-group">
                      <label class="control-label">Date</label>
                      <vuestic-date-picker id="scheduled_at_day_picker" v-model="scheduleData.date"
                        :config="datePickerConfig" />
                      <i class="bar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectScheduleTime('09:00')"
                    :class="{ 'btn-primary': scheduleData.timeSelect === '09:00' }">09:00 AM</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectScheduleTime('11:00')"
                    :class="{ 'btn-primary': scheduleData.timeSelect === '11:00' }">11:00 AM</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectScheduleTime('16:00')"
                    :class="{ 'btn-primary': scheduleData.timeSelect === '16:00' }">04:00 PM</button>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="form-group">
                    <div class="input-group">
                      <label class="control-label">Time</label>
                      <vuestic-date-picker id="scheduled_at_time_picker" v-model="scheduleData.time"
                        :config="timePickerConfig" />
                      <i class="bar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <multiselect v-model="timezoneScheduled" :options="timezones" :multiple="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone" label="label"
                track-by="label" :preselect-first="true" :show-labels="false">
              </multiselect>
            </div>
            <div class="col-md-12 mt-3">
              <p>Stop on Response</p>
              <vuestic-switch v-model="scheduleData.stopOnResponse" :offcolor="true" style="width: 200px">
                <span slot="trueTitle">On</span>
                <span slot="falseTitle">Off</span>
              </vuestic-switch>
            </div>
            <div v-if="!scheduleMessageValid" class="col-md-12 mt-3">
              <b-alert class="err-message text-center" show variant="danger">Scheduled date and time must be in the
                future.</b-alert>
            </div>
          </div>
          <div slot="footer">
            <div class="col-md-12 mt-3 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="invalid || processingSchedule || !scheduleMessageValid"
                style="min-width: 80px;">
                <atom-spinner v-if="processingSchedule" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Schedule</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalCancelSchedule" @ok="cancelSchedule" @cancel="isOpenModalCancelSchedule = false"
      okText="Proceed" okClass="btn btn-danger" cancelText="Cancel">
      <span slot="title" class="text-danger">Cancel Scheduled {{ scheduleItemTitle }}</span>
      <p>Are you sure you want to cancel this Scheduled {{ scheduleItemTitle }}? This can not be undone.</p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalAutomationAdd" @cancel="onCloseModalAutomation" :cancelShown="false"
      :okShown="false">
      <span slot="title">Run Automation</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(onAddAutomation)">
          <div class="row">
            <div class="col-md-12 mb-4">
              <label class="input-label">Automation</label>
              <multiselect :show-labels="false" v-model="selectedAutomation" :options="automations" :multiple="false"
                :preserve-search="true" :allowEmpty="false" placeholder="Select Automation" label="name" track-by="id">
              </multiselect>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="automationProcessing || invalid">
                <atom-spinner v-if="automationProcessing" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" />
                <span v-else>Start</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalAutomationDelete" @ok="handleDeleteAutomation"
      @cancel="isOpenModalAutomationDelete = false" okText="Stop" cancelText="Cancel" :closeOnOk="false"
      :processing="automationProcessing" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Stop Automation?</span>

      <div>Confirm you want to stop the <b>{{ selectedAutomationProcess ? selectedAutomationProcess.name : 'N/A' }}</b>
        automation?</div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenStopOnReply" @ok="handleConfirmStopOnReply(true)"
      @cancel="handleConfirmStopOnReply(false)" :force="true" okText="Yes" cancelText="No" :closeOnOk="false"
      :processing="automationProcessing" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Automation is in process</span>

      <div>There is an active automation running for this contact. Would you like to stop the automation?</div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenMediaModal" @ok="cancelMediaModal" @cancel="cancelMediaModal" okText="Ok"
      :cancelShown="false" :closeOnOk="false" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Attachment</span>

      <div v-if="selectedAttachment">
        <div class="text-center pb-2">
          <img v-if="checkMediaType(selectedAttachment, ['image'])" :src="selectedAttachment.path"
            style="max-width: 400px" />
          <span v-else><i class="fa fa-file" style="font-size: 60px;" aria-hidden="true"></i></span>
        </div>
        <div class="mt-2">
          <p><b>FileName: </b> {{ selectedAttachment.filename ? selectedAttachment.filename : 'N/A' }}</p>
          <p><b>FileSize: </b> {{ selectedAttachment.size ? fileSize(selectedAttachment.size) : 'N/A' }}</p>
          <p><b>FileType: </b> {{ selectedAttachment.type ? selectedAttachment.type : 'N/A' }}</p>
        </div>
      </div>
      <div slot="footer">
        <a v-if="selectedAttachment" :href="selectedAttachment.path" target="_blank" class="btn btn-primary">Download <i
            class="fa fa-download" aria-hidden="true"></i></a>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalSendICS" @cancel="handleCloseModalSendICS" :cancelShown="false" :okShown="false"
      :large="true">
      <span slot="title" class="text-primary">Add Calendar Link</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="sendMessageActionForm">
        <form @submit.prevent="handleSubmit(handleSendICS)" class="sendMessageActionForm">
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectSendIcsDay('today')"
                    :class="{ 'btn-primary': sendICSParams.daySelect === 'today' }">Today</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectSendIcsDay('tomorrow')"
                    :class="{ 'btn-primary': sendICSParams.daySelect === 'tomorrow' }">Tomorrow</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectSendIcsDay('after_tomorrow')"
                    :class="{ 'btn-primary': sendICSParams.daySelect === 'after_tomorrow' }">{{ afterTomorrow }}</button>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="form-group">
                    <div class="input-group">
                      <label class="control-label">Date</label>
                      <vuestic-date-picker id="scheduled_at_day_picker" v-model="sendICSParams.date"
                        :config="datePickerConfig" />
                      <i class="bar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectSendIcsTime('09:00')"
                    :class="{ 'btn-primary': sendICSParams.timeSelect === '09:00' }">09:00 AM</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectSendIcsTime('11:00')"
                    :class="{ 'btn-primary': sendICSParams.timeSelect === '11:00' }">11:00 AM</button>
                </div>
                <div class="col-md-12 mb-3">
                  <button type="button" class="w-100 btn btn-outline" @click="onSelectSendIcsTime('16:00')"
                    :class="{ 'btn-primary': sendICSParams.timeSelect === '16:00' }">04:00 PM</button>
                </div>
                <div class="col-md-12 mt-4">
                  <div class="form-group">
                    <div class="input-group">
                      <label class="control-label">Time</label>
                      <vuestic-date-picker id="scheduled_at_time_picker" v-model="sendICSParams.time"
                        :config="timePickerConfig" />
                      <i class="bar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <multiselect v-model="sendICSParams.timezone" :options="timezones" :multiple="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone" label="label"
                track-by="label" :preselect-first="true" :show-labels="false">
              </multiselect>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <validation-provider rules="required" v-slot="{ }" name="Title">
                <label>Title</label>
                <TextareaEmojiPicker v-model="sendICSParams.title" :focused="true" :rows="2" :enabledMms="false"
                  ref="textareaEmoji" :showSendButton="false" :hasHoverEffect="false" />
              </validation-provider>
            </div>
            <div class="col-md-12 mt-4">
              <validation-provider rules="required" v-slot="{ }" name="Description">
                <label>Description</label>
                <TextareaEmojiPicker v-model="sendICSParams.description" :focused="true" :rows="4" :enabledMms="false"
                  ref="textareaEmoji" :showSendButton="false" :hasHoverEffect="false" />
              </validation-provider>
            </div>
            <div v-if="!icsTimeValid" class="col-md-12 mt-3">
              <b-alert class="err-message text-center" show variant="danger">Meeting date and time must be in the
                future.</b-alert>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="!icsTimeValid || invalid || isSendingICS"
                style="min-width: 80px;">
                <atom-spinner v-if="isSendingICS" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                <span v-else>Add Link</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";
import AppDropdownSelect from "../../common/app-dropdown-select/AppDropdownSelect";
import TextareaEmojiPicker from "../../common/TextareaEmojiPicker";
import TextEditorPicker from "../../common/TextEditorPicker";
import DropzoneUpload from "../../common/DropzoneUpload";
import SMSLength from '../../common/SMSLength'
import { parsePhoneNumberFromString } from "libphonenumber-js";
import TagSelector from "../../common/TagSelector";
import SearchInput from "../../common/SearchInput";
import Pusher from "pusher-js";
import CustomFields from '../Contact/CustomFields';
import TaskTab from './Componenets/TaskTab.vue';

export default {
  components: {
    AppDropdownSelect, TextareaEmojiPicker, TextEditorPicker, DropzoneUpload,
    TagSelector, SearchInput, SMSLength, CustomFields, TaskTab,
  },

  data() {
    return {
      installId: undefined,
      contactFiles: [],
      loadingContactFiles: false,
      newAgent: undefined,
      tagProcessing: false,
      isOpenModalTags: false,
      isOpenModalDelete: false,
      pusherObj: null,
      text: "",
      loading: false,
      loadingNewContact: false,
      loadingContacts: false,
      loadingConversations: false,
      loadingSend: false,
      loadingUpdateContact: false,
      loadingUpdateContactField: null,
      processingContactOpt: false,
      processingContact: false,
      processingTemplate: false,
      dropzoneProcessing: false,
      statusFilter: undefined,
      tagsSelected: [],
      filerOptions: [
        { id: "open", label: "Open" },
        { id: "unread", label: "Unread" },
        { id: "scheduled", label: "Scheduled" },
        { id: "sent", label: "Sent" },
        { id: "deleted", label: "Closed" },
        { id: "optout", label: "OptOut" },
        { id: "blocked", label: "Blocked" },
        { id: "all", label: "All" },
      ],
      timeFilerOptions: [
        { icon: "", label: "Most Recent", value: "1" },
        { icon: "", label: "Longest Wait", value: "2" },
      ],
      markOptions: [
        { icon: "", label: "Unread", value: "unread" },
        { icon: "", label: "Closed", value: "deleted" },
      ],
      snoozeOptions: [
        { icon: "", label: "1 Hour", value: "1" },
        { icon: "", label: "2 Hour", value: "2" },
        { icon: "", label: "Tomorrow (AM)", value: "3" },
        { icon: "", label: "Tomorrow (PM)", value: "4" },
        { icon: "", label: "Next Week", value: "5" },
        { icon: "", label: "Choose Date/Time", value: "6" },
      ],
      smsTabNames: [
        "Message",
        // 'Note'
      ],
      smsTabSelected: "Message",
      newMessage: "",
      newEmail: "",
      conversations: [],
      selectedInstall: null,
      contacts: [],
      selectedContact: null,
      pusherChannel: null,
      hasContactScrollListener: null,
      hasMessageScrollListener: null,
      defaultContactPagination: {
        loadMore: true,
        page: 1,
        per_page: 30,
      },
      defaultMessagePagination: {
        loadMore: true,
        page: 1,
        per_page: 30,
      },
      contactPagination: {},
      messagePagination: {},
      editContactOpened: false,
      defaultContactDetail: {
        first_name: "",
        last_name: "",
        email: "",
        bday: "",
        anniversary: "",
        note: "",
        tags: [],
        automations: [],
        city: "",
        custom_fields: {}
      },
      contactDetail: {},
      searchFilter: "",
      isOpenModalCreate: false,
      newContactForm: {},
      newContactDefault: {
        first_name: "",
        last_name: "",
        number: "",
        email: "",
        bday: "",
        anniversary: "",
        note: "",
        city: "",
        custom_fields: {}
      },
      isConfirmed: false,
      loadingDeleteTag: false,
      users: [],
      departments: [],
      selectedDepartment: undefined,
      isOpenModalSaveReply: false,
      newTemplate: {
        name: "",
        message: "",
        medias: [],
      },
      scheduleMessageOpened: false,
      scheduleData: {},
      datePickerConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
      isOpenModalCancelSchedule: false,
      cancelConversationItem: undefined,
      processingSchedule: false,
      timezoneScheduled: undefined,
      isOpenModalAutomationAdd: false,
      isOpenModalAutomationDelete: false,
      selectedAutomationProcess: null,
      loadingAutomation: false,
      automationProcessing: false,
      automations: [],
      selectedAutomation: null,
      isOpenBlockDelete: false,
      isOpenStopOnReply: false,
      selectedImages: [],
      isOpenMediaModal: false,
      selectedAttachment: null,
      isOpenModalSendICS: false,
      sendICSParams: {},
      isSendingICS: false,
      icsFiles: [],
      emailIcsFile: undefined,
      isEmailCalendar: false,
      activeTabIndex: 0,
    };
  },

  created() {
    if (this.$store.getters["auth/isBusinessManager"]) {
      if (this.$store.getters["auth/widgetTypePhoneOnly"]) {
        this.$router.push({ name: "dashboard" });
      }
    } else if (this.user.role == "BusinessUser") {
      if (!["3", "4"].includes(this.user.user_type)) {
        this.$router.push({ name: "dashboard" });
      } else {
        if (!this.user.agent_type) {
          this.$router.push({ name: "business.setting" });
        } else {
          if (
            this.user.agent_type == "voiceAgent" ||
            this.$store.getters["auth/widgetTypePhoneOnly"]
          ) {
            this.$router.push({ name: "calls" });
          }

          if (this.user.user_type == "4" && !this.$store.getters["auth/customPageType"].includes('inbox_page')) {
            this.$router.push({ name: "business.setting" });
          }
        }
      }
    }
  },

  computed: {
    scheduleItemTitle() {
      let res = 'Message'
      if (this.cancelConversationItem) {
        switch (this.cancelConversationItem.type) {
          case 'message':
            if (this.cancelConversationItem.source_type === 3)
              res = 'Email'
            else
              res = 'Message';
            break;
          case 'call':
          case 'callScheduled':
            res = 'Call';
          default:
            break;
        }
      }
      return res;
    },

    showFiles() {
      return this.enabledMms
    },

    showTasks() {
      return true
    },

    enableEmail() {
      return (this.hasSendgridAvailable || this.hasEmailClientAvailable) && this.selectedContact.email
    },

    timezones() {
      return this.$store.getters["timeZones"];
    },

    timezone() {
      let selectedInstallData = undefined
      if (this.selectedInstall) {
        selectedInstallData = this.installs.find(
          (item) => item.id == this.selectedInstall.id
        );
      }
      if (selectedInstallData && selectedInstallData.properties && selectedInstallData.properties.timezone) {
        return selectedInstallData.properties.timezone.value;
      } else {
        return moment.tz.guess();
      }
    },
    conversationSorted() {
      return this.conversations.sort((a, b) => new Date(a.sort_time) - new Date(b.sort_time));
    },
    icsTimeValid() {
      if (this.sendICSParams && this.sendICSParams.timezone) {
        return moment
          .tz(this.sendICSParams.date + " " + this.sendICSParams.time, "YYYY-MM-DD hh:mm A", this.sendICSParams.timezone.value)
          .isAfter()
      }
      return false
    },
    scheduleMessageValid() {
      if (this.scheduleData && this.timezoneScheduled) {
        return moment
          .tz(this.scheduleData.date + " " + this.scheduleData.time, "YYYY-MM-DD hh:mm A", this.timezoneScheduled.value)
          .isAfter()
      }
      return false
    },
    afterTomorrow() {
      return moment().add(2, 'days').format('dddd')
    },
    businesNumberOfMessages() {
      let result = 0;
      if (this.user && this.user.number_of_messages)
        result = parseInt(this.user.number_of_messages);
      return result;
    },

    enabledMms() {
      return this.installSmsNumber && this.installSmsNumber.startsWith("+1");
    },

    businessOwner() {
      const user = this.$store.getters["auth/businessOwner"];
      return {
        id: user ? user.id : '',
        name: user ? user.name : '',
        full_name: user ? user.name : '',
        first_name: user ? user.properties.firstName : '',
        last_name: user ? user.properties.lastName : '',
        avatar: user ? user.avatar : '',
      }
    },

    businessDepartmentEnabled() {
      return this.$store.getters["auth/departmentEnabled"];
    },

    installDepartmentEnabled() {
      if (this.selectedInstall) {
        const selectedInstallData = this.installs.find(
          (item) => item.id == this.selectedInstall.id
        );
        return (
          this.businessDepartmentEnabled &&
          selectedInstallData &&
          selectedInstallData.department_enabled
        );
      } else {
        return false;
      }
    },

    userList() {
      return this.users.map((item) => {
        return {
          ...item,
          label: item.full_name + " ( " + item.phone + " ) ",
        };
      });
    },

    departmentList() {
      let result = this.departments.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
      if (result && result.length > 1)
        result = [{ id: 0, label: "All" }, ...result];
      return result;
    },

    installSmsNumber() {
      const item = this.installs.find(
        (item) => item.id == this.selectedInstall.id
      );
      if (item) return item.sms_number;
      return "";
    },

    smsLimit() {
      if (
        this.business && this.business.messages >= this.business.number_of_messages
      )
        return true;
      return false;
    },

    smsLimitClose() {
      if (
        this.business && this.business.messages >= this.business.number_of_messages * 0.9
      )
        return true;
      return false;
    },

    agencyEmail() {
      return (this.user && this.user.agency && this.user.agency.email) || "";
    },

    installs() {
      return this.installData.filter((item) => item.status === "active");
    },

    optionInstalls() {
      return this.installs.map((install) => {
        return this.formatInstall(install);
      });
    },

    ...mapState("contact", {
      contactProcessing: "processing",
    }),
    ...mapState("install", {
      installDidFetch: "didFetch",
      installFetching: "fetching",
      installData: "data",
      installSelectedId: "selectedId",
    }),

    ...mapGetters("auth", {
      user: "user",
      business: "business",
      hasSendgridAvailable: "hasSendgridAvailable",
      hasEmailClientAvailable: "hasEmailClientAvailable",
    }),
  },

  mounted() {
    this.$store
      .dispatch("auth/getLiveData")
    this.initScheduleData();

    this.statusFilter = this.filerOptions[0];

    if (this.$route.query.installId) {
      this.installId = this.$route.query.installId
    }

    if (this.$route.query.search) {
      this.searchFilter = this.$route.query.search;
    }

    if (this.$route.query.installId || this.$route.query.search) {
      const url = new URL(window.location);
      url.searchParams.delete('installId');
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }

    this.pusherObj = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    });

    this.contactPagination = { ...this.defaultContactPagination };
    this.messagePagination = { ...this.defaultMessagePagination };

    this.getInstalls();
    this.timezoneScheduled = this.timezones.find(
      (item) => item.value === this.timezone
    );
    this.loadAutomations()
  },

  beforeDestroy() {
    this.$store.commit("contact/setSelectedId", undefined);
    this.$store.commit("install/selectInstall", undefined);
    this.unsubscribeChannel();
  },

  watch: {
    optionInstalls: {
      handler: function (newValue) {
        if (newValue && this.selectedInstall) {
          this.selectedInstall = newValue.find(
            (item) => item.id === this.selectedInstall.id
          );
        }
      },
    },
    selectedDepartment: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.loadUsers();
        }
      },
    },
    selectedInstall: {
      handler: function (newValue, oldValue) {
        if (newValue && (!oldValue || oldValue.id !== newValue.id)) {
          this.timezoneScheduled = this.timezones.find(
            (item) => item.value === this.timezone
          );
          if (this.installDepartmentEnabled) {
            this.loadDepartments();
          } else {
            this.loadInstallUsers();
          }
        }
      },
    },
    selectedContact: {
      handler: function (newValue) {
        if (newValue) {
          this.contactDetail = {
            first_name: newValue.first_name,
            last_name: newValue.last_name,
            email: newValue.email,
            bday: newValue.bday,
            anniversary: newValue.anniversary,
            city: newValue.city,
            note: newValue.note,
            tags: newValue.tags,
            automations: newValue.automations,
            custom_fields: newValue.custom_fields
          };

          let agent = newValue.agent;
          if (agent) {
            this.newAgent = {
              ...agent,
              label: agent.full_name + " ( " + agent.phone + " ) ",
            };
          } else {
            this.newAgent = undefined;
          }
        } else {
          this.contactDetail = { ...this.defaultContactDetail };
        }
      },
      deep: true,
    },
    users: {
      handler: function (newValue) {
        if (newValue) {
          if (this.selectedContact) {
            let agent = this.selectedContact.agent;
            if (agent) {
              this.newAgent = {
                ...agent,
                label: agent.full_name + " ( " + agent.phone + " ) ",
              };
            } else {
              this.newAgent = undefined;
            }
          }
        }
      },
      deep: true,
    }
  },

  methods: {
    sanitizeHtml: sanitizeHtml,

    openError(item) {
      let url = 'https://www.twilio.com/docs/api/errors/' + item.error_code
      window.open(url, '_blank');
    },

    onCopyUrl: function (e) {
      Vue.$toast.open({
        message: "URL has been copied to clipboard",
        type: "success",
      });
    },
    onCopyUrlError: function (e) {
      Vue.$toast.open({
        message: "Failed to copy URL to your clipboard",
        type: "failed",
      });
    },

    onOpenMedia(item) {
      this.selectedAttachment = item
      this.isOpenMediaModal = true
    },
    cancelMediaModal() {
      this.isOpenMediaModal = false
    },
    loadAutomations() {
      this.$store.dispatch('automation/all')
        .then((res) => {
          this.automations = res.filter(item => item.active)
        })
        .catch(() => {
        })
    },
    refreshConversation() {
      this.processingContact = true;
      this.$store
        .dispatch("chat/refresh", {
          contactId: this.selectedContact.id
        })
        .then(() => {
          this.selectContact(this.selectedContact, true);
          this.processingContact = false;
        })
        .catch(() => {
          this.processingContact = false;
        });
    },
    handleAttachments(images) {
    },

    onCancelScheduleCall(item) {
      this.cancelConversationItem = item
      this.isOpenModalCancelSchedule = true;
    },

    onCancelSchedule(item) {
      this.cancelConversationItem = item
      this.isOpenModalCancelSchedule = true;
    },
    cancelSchedule() {
      this.processingSchedule = true
      const param = {
        contactId: this.selectedContact.id,
        type: this.cancelConversationItem.type,
        id: this.cancelConversationItem.id,
      }
      this.$store
        .dispatch("chat/cancelSchedule", param)
        .then((message) => {
          this.processingSchedule = false
          this.isOpenModalCancelSchedule = false
          const index = this.conversations.findIndex(row => row.id === this.cancelConversationItem.id)
          if (index !== -1) {
            this.conversations.splice(index, 1)
          }
          this.cancelConversationItem = undefined
        })
        .catch((err) => {
          this.processingSchedule = false
          this.isOpenModalCancelSchedule = false
        });
    },
    initScheduleData() {
      this.scheduleData = {
        daySelect: 'today',
        timeSelect: '11:00',
        date: moment().format("YYYY-MM-DD"),
        time: "11:00",
        stopOnResponse: false,
        active: false,
      }
    },

    onSubmitSchedule() {
      this.scheduleData.active = true;
      this.onCloseModalScheduleMessage();
    },

    onSelectScheduleDay(val) {
      this.scheduleData.daySelect = val
      switch (val) {
        case 'tomorrow':
          this.scheduleData.date = moment().add(1, 'days').format("YYYY-MM-DD")
          break;
        case 'after_tomorrow':
          this.scheduleData.date = moment().add(2, 'days').format("YYYY-MM-DD")
          break;
        case 'today':
        default:
          this.scheduleData.date = moment().format("YYYY-MM-DD")
          break;
      }
    },

    getSmsDate(sms) {
      return moment(sms.created_at).tz(this.timezone).format("dddd, MMM D")
    },

    getSmsTime(sms) {
      return moment(sms.delivered_at || (sms.sent_at || sms.created_at)).tz(this.timezone).format("h:mm A")
    },

    onSelectScheduleTime(val) {
      this.scheduleData.timeSelect = val
      this.scheduleData.time = val
    },

    onSelectSendIcsDay(val) {
      this.sendICSParams.daySelect = val

      switch (val) {
        case 'tomorrow':
          this.sendICSParams.date = moment().add(1, 'days').format("YYYY-MM-DD")
          break
        case 'after_tomorrow':
          this.sendICSParams.date = moment().add(2, 'days').format("YYYY-MM-DD")
          break
        case 'today':
        default:
          this.sendICSParams.date = moment().format("YYYY-MM-DD")
          break
      }
    },

    onSelectSendIcsTime(val) {
      this.sendICSParams.timeSelect = val
      this.sendICSParams.time = val
    },

    isValid(number) {
      let tmp = number;
      if (!tmp)
        return '';
      if (!tmp.startsWith('+'))
        tmp = '+' + tmp;

      try {
        let phoneNumber = parsePhoneNumberFromString(tmp)
        phoneNumber = phoneNumber.formatNational()
        return true;
      } catch (error) {
        return false
      }
    },
    onOpenContactInfo() {
      this.editContactOpened = true
    },

    onSchedule() {
      if (!this.scheduleData.active) {
        this.scheduleMessageOpened = true
      } else {
        this.scheduleData.active = false;
      }
    },
    onEmailSendICS() {
      if(this.emailIcsFile != undefined) {
          this.emailIcsFile = undefined
          return
      } 
      this.isEmailCalendar = true
      this.isOpenModalSendICS = true
      this.sendICSParams = {
        timezone: this.timezones.find(
          (item) => item.value === moment.tz.guess()
        ),
        daySelect: 'today',
        timeSelect: '11:00',
        date: moment().format("YYYY-MM-DD"),
        time: "11:00",
      }
    },

    onSendICS() {
      this.isOpenModalSendICS = true
      this.sendICSParams = {
        timezone: this.timezones.find(
          (item) => item.value === moment.tz.guess()
        ),
        daySelect: 'today',
        timeSelect: '11:00',
        date: moment().format("YYYY-MM-DD"),
        time: "11:00",
      }
    },
    handleCloseModalSendICS() {
      this.isEmailCalendar = false
      this.isOpenModalSendICS = false
    },

    handleSendICS() {
      this.isSendingICS = true
      this.sendICSParams.contact_id = this.selectedContact.id

      this.$store
        .dispatch("chat/sendICS", this.sendICSParams)
        .then((icsFile) => {

          if(this.isEmailCalendar) {
            this.emailIcsFile = {
            'filename': icsFile.name,
            'path' : icsFile.url,
            'type': 'text/calendar',
           }
           this.isSendingICS = false
          } else {
            this.newMessage += ` ${icsFile.url}`
            this.isSendingICS = false
            this.icsFiles.push(icsFile.id)
          }
          this.handleCloseModalSendICS()
         
        })
        .catch(() => {
          this.isSendingICS = false
        });
    },

    onCloseModalScheduleMessage() {
      this.scheduleMessageOpened = false
    },

    onDropzoneProcessing(val) {
      this.dropzoneProcessing = val;
    },

    dropzoneUpdated(data) {
      const { medias } = data;
      this.newTemplate.medias = medias;
    },

    onSaveReply() {
      const param = {
        ...this.newTemplate,
      };
      this.processingTemplate = true;
      this.$store
        .dispatch("template/create", param)
        .then(() => {
          this.processingTemplate = false;
          this.onCloseModalSaveReply();
          this.$refs.textareaEmoji.loadTemplates();
        })
        .catch(() => {
          this.processingTemplate = false;
        });
    },

    onOpenModalSaveReply(data) {
      const { message, images } = data;
      this.newTemplate.message = message;
      this.newTemplate.medias = [...images];

      if (this.$refs.dropzoneUpload) {
        this.$refs.dropzoneUpload.removeAllFiles();
        this.$refs.dropzoneUpload.manuallyAddFile([...images]);
      }

      this.isOpenModalSaveReply = true;
    },

    onCloseModalSaveReply() {
      this.isOpenModalSaveReply = false;
    },

    formatInstall(install) {
      return {
        id: install.id,
        number: install.sms_number,
        label:
          this.formatPhone(install.sms_number) +
          " : " +
          install.properties.website,
        number_of_messages: install.number_of_messages,
      };
    },

    checkMediaType(media, types) {
      let isValid = false;
      types.forEach((type) => {
        if (media.type && media.type.startsWith(`${type}/`)) {
          isValid = true;
        }
      });

      return isValid;
    },

    onDepartmentChange() {
      this.refreshInbox();
    },

    onRemoveAssignment() {
      this.$store
        .dispatch("contact/deleteAgent", this.selectedContact.id)
        .then((contact) => {
          const contactIndex = this.contacts.findIndex(
            (item) => item.id === contact.id
          );
          if (contactIndex === -1) {
            this.contacts = [contact, ...this.contacts];
          } else {
            this.contacts.splice(contactIndex, 1, contact);
          }
          this.selectedContact = contact;
        })
        .catch(() => { });
    },

    onAssignmentChange() {
      const param = {
        contactId: this.selectedContact.id,
        agentId: this.newAgent.id,
      };
      this.$store
        .dispatch("contact/assignAgent", param)
        .then((contact) => {
          const contactIndex = this.contacts.findIndex(
            (item) => item.id === contact.id
          );
          if (contactIndex === -1) {
            this.contacts = [contact, ...this.contacts];
          } else {
            this.contacts.splice(contactIndex, 1, contact);
          }
          this.selectedContact = contact;
        })
        .catch(() => { });
    },

    loadDepartments() {
      this.$store
        .dispatch("department/smsDepartments")
        .then((data) => {
          this.departments = data;
          this.selectedDepartment = this.departmentList[0];
        })
        .catch(() => { });
    },

    loadInstallUsers() {
      this.newAgent = undefined;
      this.users = [];
      this.$store
        .dispatch("install/smsAgents", this.selectedInstall.id)
        .then((data) => {
          this.users = data;
        })
        .catch(() => { });
    },

    loadUsers() {
      this.newAgent = undefined;
      this.users = [];
      this.$store
        .dispatch(
          "department/smsAgents",
          // this.selectedDepartment ? this.selectedDepartment.id : 0
          0,
        )
        .then((data) => {
          this.users = data;
        })
        .catch(() => { });
    },

    onDeleteContact() {
      this.isOpenModalDelete = true;
    },

    handleDeleteContact() {
      this.processingContact = true;
      this.$store
        .dispatch("contact/delete", this.selectedContact.id)
        .then((res) => {
          if (res) {
            this.contacts = this.contacts.filter(
              (item) => item.id !== this.selectedContact.id
            );
            if (this.contacts.length) this.selectContact(this.contacts[0]);
            else this.selectContact(null);
          }
          this.processingContact = false;
          this.isOpenModalDelete = false;
        })
        .catch(() => {
          this.processingContact = false;
          this.isOpenModalDelete = false;
        });
    },

    handleBlockContact() {
      this.processingContact = true;
      this.$store
        .dispatch("contact/block", this.selectedContact.id)
        .then((res) => {
          if (res) {
            this.contacts = this.contacts.filter(
              (item) => item.id !== this.selectedContact.id
            );
            if (this.contacts.length) this.selectContact(this.contacts[0]);
            else this.selectContact(null);
          }
          this.processingContact = false;
          this.isOpenBlockDelete = false;
        })
        .catch(() => {
          this.processingContact = false;
          this.isOpenBlockDelete = false;
        });
    },

    handleUnblockContact() {
      this.processingContact = true;
      this.$store
        .dispatch("contact/unblock", this.selectedContact.id)
        .then((res) => {
          if (res) {
            this.contacts = this.contacts.filter(
              (item) => item.id !== this.selectedContact.id
            );
            if (this.contacts.length) this.selectContact(this.contacts[0]);
            else this.selectContact(null);
          }
          this.processingContact = false;
        })
        .catch(() => {
          this.processingContact = false;
        });
    },

    onOptContact() {
      this.processingContactOpt = true;
      this.$store
        .dispatch("contact/toggleOptOut", this.selectedContact.id)
        .then((res) => {
          const itemIndex = this.contacts.findIndex(
            (item) => item.id === this.selectedContact.id
          );
          if (itemIndex !== -1) {
            this.contacts.splice(itemIndex, 1);
            this.conversations = [];
            if (this.contacts && this.contacts.length) {
              const i = this.contacts[itemIndex] ? itemIndex : Object.keys(this.contacts)[this.contacts.length - 1]
              this.selectContact(this.contacts[i])
            } else {
              this.selectedContact = null
            }
          }
          this.processingContactOpt = false;
        })
        .catch(() => {
          this.processingContactOpt = false;
        });
    },

    topFromText(contact) {
      let result = '';
      if (contact.website) {
        result = "This text originated from " + contact.website;
        if (contact.fromDepartment) {
          result += ", " + contact.fromDepartment.name;
        }
      } else {
        switch (contact.source) {
          case 2:
            result = "This text originated from directly texting the number";
            break;
          case 3:
            result = "This text originated from Zapier";
            break;
          case 4:
            result = "This text originated from within the system to the contact";
            break;
          case 1:
          default:
            break;
        }
      }
      return result;
    },

    formatMessageTimestamp(item) {
      let result = "";
      if (item) {
        if (item.outgoing) result = "Sent at ";
        else result = "Received at ";

      }
      if (item.type === 'call') {
        result = result + moment(item.created_at).tz(this.timezone).format("h:mma MMMM DD, YYYY") + ` - ${item.status_desc}`
      } else if (item.type === 'callScheduled') {
        result = 'Scheduled at ' + moment(item.date).tz(this.timezone).format("h:mma MMMM DD, YYYY")
      } else {
        result = result + moment(item.updated_at).tz(this.timezone).format("h:mma MMMM DD, YYYY");
      }

      if (item.is_link_opened) {
        result += ', Link Opened'
      }

      if (item.source_type === 6) {
        result += ', Transcription'
      }

      return result;
    },

    formatPhone(val) {
      if (!val) return "";
      if (!val.startsWith("+")) val = "+" + val;

      const phoneNumber = parsePhoneNumberFromString(val);
      if (phoneNumber)
        return phoneNumber.formatNational()
      return val
    },

    formatMessage(item) {
      let message = item.message;
      if (item.is_broadcast) {
        return "Broadcast: <br/><br/>" + message;
      }
      if (item.is_scheduled && item.scheduled_at) {
        if (item.source_type === 3)
          return "Scheduled Email: <br/><br/>" + message;
        else
          return "Scheduled Message: <br/><br/>" + message;
      }
      return this.sanitizeHtml(message, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
        allowedSchemesByTag: { img: ['data', 'https'] },
      });
    },

    getAutomationTooltip(item) {
      let result = '';
      if (item.process_cnt) {
        let endAt = null;
        let startAt = null;
        item.processes.forEach(process => {
          startAt = process.start_at ? process.start_at : null
          endAt = process.end_at ? process.end_at : null
        })
        if (!startAt)
          startAt = item.created_at
        if (endAt) {
          result += `Completed: ${this.$options.filters.shorttime(endAt, this.timezone)}`
        } else if (startAt) {
          result += `Started: ${this.$options.filters.shorttime(startAt, this.timezone)}`
        }

        let completed = item.processes.filter(process => process.end_at).length
        let percent = parseInt(completed / item.process_cnt * 100);
        result += ` - ${percent}%`
      } else {
        result += `Started: ${this.$options.filters.shorttime(item.created_at, this.timezone)} - 0%`
      }
      return result
    },

    onAddAutomation() {
      if (!this.selectedAutomation) {
        Vue.$toast.open({
          message: 'Please select automation to start.',
          type: 'error',
        })
        return false
      }

      const params = {
        contactId: this.selectedContact.id,
        automationId: this.selectedAutomation.id,
      }

      this.automationProcessing = true

      this.$store
        .dispatch('contact/startAutomation', params)
        .then((res) => {
          this.automationProcessing = false
          this.isOpenModalAutomationAdd = false
          let i = this.selectedContact.automations.findIndex(item => item.id === res.id)
          if (i !== -1) {
            this.selectedContact.automations.splice(i, 1, res)
          } else {
            this.selectedContact.automations.push(res)
          }
        })
        .catch(() => {
          this.automationProcessing = false
        })
    },

    onCloseModalAutomation() {
      this.isOpenModalAutomationAdd = false;
    },

    onAddAutomationClick() {
      this.loadAutomations()

      this.isOpenModalAutomationAdd = true;
    },

    onDeleteAutomation(item) {
      this.isOpenModalAutomationDelete = true;
      this.selectedAutomationProcess = item;
    },

    handleDeleteAutomation() {
      this.isOpenModalAutomationDelete = false;

      const params = {
        contactId: this.selectedContact.id,
        automationId: this.selectedAutomationProcess.id,
      }

      this.automationProcessing = true

      this.$store
        .dispatch('contact/stopAutomation', params)
        .then(() => {
          this.automationProcessing = false
          const index = this.contacts.findIndex(
            (item) => item.id == this.selectedContact.id
          );

          let i = this.selectedContact.automations.findIndex(item => item.id === this.selectedAutomationProcess.id)
          if (i !== -1) {
            const now = moment
              .tz(this.scheduleData.date + " " + this.scheduleData.time, "YYYY-MM-DD hh:mm A", this.timezoneScheduled.value)
              .utc()
              .format("YYYY-MM-DD HH:mm:00");

            this.selectedContact.automations[i].processes = this.selectedContact.automations[i].processes.map(item => {
              return {
                ...item,
                stop_at: now,
                end_at: now
              }
            })
          }

          this.contacts[index] = this.selectedContact;

        })
        .catch(() => {
        })
    },

    onAddTag() {
      this.tagsSelected = this.contactDetail.tags;
      this.isOpenModalTags = true;
    },

    onDeleteTag(item) {
      this.tagsSelected = this.contactDetail.tags;
      this.tagsSelected = this.tagsSelected.filter((tag) => {
        return tag.id !== item.id;
      });
      this.onTagContact();
    },

    onTagContact() {
      this.loadingDeleteTag = true;
      const payload = {
        id: this.selectedContact.id,
        data: {
          ...this.contactDetail,
          tags: this.tagsSelected,
        },
      };

      this.$store
        .dispatch("contact/update", payload)
        .then((contact) => {
          this.loadingDeleteTag = false;
          this.isOpenModalTags = false;
          this.updateContactInList(contact);
        })
        .catch(() => {
          this.loadingDeleteTag = false;
        });
    },

    onCloseModalTag() {
      this.isOpenModalTags = false;
      this.tagsSelected = [];
    },

    onChangeTags(data) {
      this.tagsSelected = data;
    },

    saveNewContact() {
      this.loadingNewContact = true;
      const numberObj = parsePhoneNumberFromString(this.newContactForm.number);
      const type = this.isConfirmed ? "sms" : "cms";

      const submitData = {
        ...this.newContactForm,
        departmentId: this.selectedDepartment
          ? this.selectedDepartment.id
          : undefined,
        number: numberObj.number,
        type,
        tags: this.tagsSelected,
        automationId: this.selectedAutomation ? this.selectedAutomation.id : null,
      };

      this.$store
        .dispatch("chat/createContact", {
          installId: this.selectedInstall.id,
          data: submitData,
        })
        .then((contact) => {
          const contactIndex = this.contacts.findIndex(
            (item) => item.id === contact.id
          );
          if (contactIndex === -1) {
            this.contacts = [contact, ...this.contacts];
          } else {
            this.contacts.splice(contactIndex, 1, contact);
          }
          this.loadingNewContact = false;
          this.isOpenModalCreate = false;
          this.selectContact(contact);
        })
        .catch(() => {
          this.loadingNewContact = false;
        });
    },

    onNewContactButton() {
      this.newContactForm = Object.assign({}, this.newContactDefault);
      this.selectedAutomation = null;
      this.tagsSelected = [];
      this.isOpenModalCreate = true;
    },

    closeModalCreate() {
      this.isOpenModalCreate = false;
      this.newContactForm.tags = [];
    },

    refreshInbox() {
      this.contacts = [];
      this.conversations = [];
      this.selectedContact = null;
      this.getContacts();
    },

    contactTimeStamp(contact) {
      if (contact.last_message_created_at) return contact.last_message_created_at;
      return contact.updated_at;
    },

    updateContactInList(contact) {
      const index = this.contacts.findIndex(
        (item) => item.id == this.selectedContact.id
      );
      this.contacts[index] = contact;
      this.selectedContact = contact;
    },

    handleContactInputBlur(event, key) {
      this.loadingUpdateContactField = key
      const value = this.contactDetail[key]

      // Value not changed    
      if (!value && !this.selectedContact[key] || value === this.selectedContact[key]) return;
      this.updateContact()
    },

    updateContact() {
      this.loadingUpdateContact = true;

      this.$store
        .dispatch("chat/updateContact", {
          contactId: this.selectedContact.id,
          data: {
            ...this.contactDetail,
          },
        })
        .then((contact) => {
          this.updateContactInList(contact);

          this.loadingUpdateContact = false;
          this.loadingUpdateContactField = null;
        })
        .catch(() => {
          this.loadingUpdateContact = false;
          this.loadingUpdateContactField = null;
        });
    },

    toggleContactDetail() {
      this.editContactOpened = !this.editContactOpened;
    },

    getInstalls() {
      const param = {
        status: "active",
      };
      this.$store
        .dispatch("install/fetchAllInstalls", param)
        .then(({ data }) => {
          if (data && data.length) {
            let tmp = data[0]
            if (this.installId) {
              tmp = data.find(item => item.id == this.installId)
            }

            this.selectedInstall = this.formatInstall(tmp);
          }
          this.onChangeInstall();
        })
        .catch(() => { });
    },

    onReadMessage(install, contact) {
      const number_of_messages = parseInt(
        install.number_of_messages > 0 ? install.number_of_messages - 1 : 0
      );
      contact.status = "";
      const installData = {
        id: install.id,
        number_of_messages: number_of_messages,
      };
      this.$store.commit("install/updateInstallData", installData);
      this.$store.commit(
        "auth/updateNumberOfMessages",
        this.businesNumberOfMessages - 1
      );
    },

    selectContact(contact, manual = false) {
      this.$store.commit("contact/setSelectedId", contact.id);

      if (manual && contact && contact.status == "unread") {
        this.onReadMessage(this.selectedInstall, contact);
      }

      this.selectedContact = contact;
      this.messagePagination = { ...this.defaultMessagePagination };

      this.conversations = [];
      this.emailIcsFile = undefined
      this.getConversations(false, manual);
      this.getFiles();
    },

    onSendMessage({ images }) {
      this.selectedImages = images;

      let hasStopOnReplyAutomations = this.selectedContact.automations.find(item => {
        let hasActiveProcess = item.process_cnt && item.processes.find(item1 => !item1.end_at)
        return item.stop_on_reply && hasActiveProcess
      })

      if (!this.scheduleData.active && hasStopOnReplyAutomations) {
        this.isOpenStopOnReply = true;
      } else {
        this.sendMessage()
      }
    },

    handleConfirmStopOnReply(val) {
      this.isOpenStopOnReply = false;
      this.sendMessage(val)
    },

    checkBadword(msg) {
      let payload = {
        content: msg
      }
      return this.$store.dispatch('auth/checkBadword', payload)
        .then((res) => {
          return Promise.resolve(res.value);
        }).catch((e) => {
          return Promise.resolve(false);
        })
    },

    async resendMessage(item) {
      let hasBadword = await this.checkBadword(item.message)
      if (hasBadword) return;

      this.loadingSend = true;

      const param = {
        contactId: this.selectedContact.id,
        messageId: item.id
      }

      this.$store
        .dispatch("chat/resendMessage", param)
        .then((message) => {
          this.loadingSend = false;
          this.onMessageUpdate(message)
        })
        .catch((err) => {
          console.log(err);
          this.loadingSend = false;
        });
    },

    sendMessage(stopAutomation = false) {
      this.loadingSend = true;

      let scheduledAt = undefined
      if (this.scheduleData.active) {
        scheduledAt = moment
          .tz(this.scheduleData.date + " " + this.scheduleData.time, "YYYY-MM-DD hh:mm A", this.timezoneScheduled.value)
          .utc()
          .format("YYYY-MM-DD HH:mm:00");
      }

      const param = {
        contactId: this.selectedContact.id,
        content: this.newMessage,
        images: this.selectedImages,
        scheduledAt: scheduledAt,
        stopOnResponse: this.scheduleData.stopOnResponse,
        stopAutomation: stopAutomation,
        icsFiles: this.icsFiles,
      }

      this.$store
        .dispatch("chat/sendMessage", param)
        .then((message) => {
          this.initScheduleData();
          const index = this.contacts.findIndex(
            (item) => item.id == this.selectedContact.id
          );
          if (this.selectedContact.status === "unread") {
            this.onReadMessage(this.selectedInstall, this.selectedContact);
          }

          if (stopAutomation) {
            const now = moment
              .tz(this.scheduleData.date + " " + this.scheduleData.time, "YYYY-MM-DD hh:mm A", this.timezoneScheduled.value)
              .utc()
              .format("YYYY-MM-DD HH:mm:00");
            this.selectedContact.automations.forEach(automation => {
              if (automation.process_cnt) {
                automation.processes = automation.processes.map(item => {
                  return {
                    ...item,
                    stop_at: now,
                    end_at: now
                  }
                })
              }
            });
          }
          this.selectedContact.last_message = message;
          this.selectedContact.last_message_created_at = message.created_at;
          this.contacts[index] = this.selectedContact;

          this.conversations.push(message);
          this.scrollMessagesToBottom();
          this.icsFiles = []
          this.newMessage = '';
          this.loadingSend = false;
          if (this.$refs.textareaEmoji) {
            this.$refs.textareaEmoji.removeFiles();
          }
        })
        .catch((err) => {
          console.log(err);
          this.loadingSend = false;
        });
    },

    sendEmail({ files }) {
      this.loadingSend = true;

      let scheduledAt = undefined
      if (this.scheduleData.active) {
        scheduledAt = moment
          .tz(this.scheduleData.date + " " + this.scheduleData.time, "YYYY-MM-DD hh:mm A", this.timezoneScheduled.value)
          .utc()
          .format("YYYY-MM-DD HH:mm:00");
      }

      let attachments = []
      attachments = [...files];
      if(this.emailIcsFile != undefined) {
        attachments.push(this.emailIcsFile)
      }
      
      
      const param = {
        contactId: this.selectedContact.id,
        content: this.newEmail,
        attachments: attachments,
        scheduledAt,
        stopOnResponse: this.scheduleData.stopOnResponse,
      }

      this.$store
        .dispatch("chat/sendEmail", param)
        .then((message) => {
          this.initScheduleData();
          const index = this.contacts.findIndex(
            (item) => item.id == this.selectedContact.id
          );
          if (this.selectedContact.status === "unread") {
            this.onReadMessage(this.selectedInstall, this.selectedContact);
          }

          this.contacts[index] = this.selectedContact;

          this.conversations.push(message);
          this.scrollMessagesToBottom();
          this.newEmail = '';
          this.emailIcsFile = undefined
          this.loadingSend = false;
          if (this.$refs.texteditor) {
            this.$refs.texteditor.removeFiles();
          }
        })
        .catch((err) => {
          console.log(err);
          this.loadingSend = false;
        });
    },

    getFiles() {
      const params = {
        contactId: this.selectedContact.id,
      };
      this.loadingContactFiles = true;
      this.$store
        .dispatch("chat/getFiles", params)
        .then((res) => {
          this.contactFiles = res.filter(item => item.path);
          this.loadingContactFiles = false;
        })
        .catch(() => {
          this.contactFiles = [];
          this.loadingContactFiles = false;
        });
    },

    getConversations(nextPage = false, manual) {
      if (this.selectedContact && this.messagePagination.loadMore) {
        this.loadingConversations = true;

        if (nextPage) {
          this.messagePagination.page++;
        }
        const params = {
          contactId: this.selectedContact.id,
          keepStatus: !manual,
          ...this.messagePagination,
        };

        this.$store
          .dispatch("chat/getConversations", params)
          .then((conversations) => {
            this.loadingConversations = false;

            if (nextPage) {
              this.conversations = [...conversations, ...this.conversations];
            } else {
              this.conversations = [...conversations];
            }

            if (!conversations.length && nextPage) {
              this.messagePagination.loadMore = false;
            }

            let messageWrapper = document.querySelector(".sms-content");
            if (!this.hasMessageScrollListener) {
              this.hasMessageScrollListener = true;
              messageWrapper.addEventListener("scroll", () => {
                if (
                  messageWrapper.scrollTop < 10 &&
                  this.conversations.length &&
                  !this.loadingConversations
                ) {
                  this.getConversations(true, manual);
                }
              });
            }

            if (!nextPage) {
              this.scrollMessagesToBottom();
            }
          })
          .catch(() => {
            // this.loadingConversations = false;
          });
      }
    },

    getContacts(nextPage = false) {
      if (this.selectedInstall && this.contactPagination.loadMore) {
        const installId = this.selectedInstall.id;
        if (nextPage) {
          this.contactPagination.page++;
        }
        const params = {
          installId: installId,
          departmentId: this.selectedDepartment
            ? this.selectedDepartment.id
            : 0,
          search: this.searchFilter,
          statusFilter: this.statusFilter.id,
          ...this.contactPagination,
        };

        this.loadingContacts = true;
        this.$store
          .dispatch("chat/getInboxByInstallNew", params)
          .then((response) => {
            this.loadingContacts = false;
            if (response && response.data.data) {
              if (!response.data.data.length && nextPage) {
                this.contactPagination.loadMore = false;
              }
              if (!nextPage) {
                this.contacts = response.data.data;
                this.selectContact(this.contacts[0]);
              } else {
                this.contacts = this.contacts.concat(response.data.data);
              }
            }

            let contactWrapper = document.querySelector(
              ".contact-list-wrapper"
            );
            if (!this.hasContactScrollListener) {
              this.hasContactScrollListener = true;
              contactWrapper.addEventListener("scroll", () => {
                if (
                  contactWrapper.scrollTop >
                  contactWrapper.scrollHeight -
                  contactWrapper.offsetHeight -
                  10 &&
                  !this.loadingContacts
                ) {
                  this.getContacts(true);
                }
              });
            }
          })
          .catch(() => {
            // this.loadingContacts = false;
          });
      }
    },

    scrollMessagesToBottom() {
      let messageWrapper = document.querySelector(".sms-content");
      if (messageWrapper) {
        setTimeout(function () {
          messageWrapper.scrollTop = messageWrapper.scrollHeight;
        }, 100);
      }
    },

    scrollContactsToBottom() {
      let contactWrapper = document.querySelector(".contact-list-wrapper");
      setTimeout(function () {
        contactWrapper.scrollTop = 0;
      }, 100);
    },

    unsubscribeChannel() {
      if (this.pusherChannel) {
        this.pusherObj.unsubscribe(this.pusherChannel);
      }
    },

    onChangeInstall() {
      this.contacts = [];
      this.conversations = [];
      this.selectedContact = null;
      this.contactPagination = { ...this.defaultContactPagination };
      this.$store.commit("install/selectInstall", this.selectedInstall.id);
      this.getContacts();

      this.unsubscribeChannel();

      const selectedInstallData = this.installs.find(
        (item) => item.id == this.selectedInstall.id
      );
      if (selectedInstallData && selectedInstallData.chat_service_id) {
        this.pusherChannel = `${process.env.VUE_APP_NOTIFICATION_CHANNEL}_install_${selectedInstallData.id}_${selectedInstallData.chat_service_id}`;

        var channel = this.pusherObj.subscribe(this.pusherChannel);

        channel.bind("twilio.incoming-email", ({ data }) => {
          this.onNewMessageById(data)
        });

        channel.bind("twilio.incoming-message", ({ data }) => {
          this.onNewMessage(data)
        });

        channel.bind("twilio.incoming-message-update", ({ data }) => {
          const { message } = data;
          this.onMessageUpdate(message)
        });

        channel.bind("twilio.incoming-call", ({ data }) => {
          this.onNewMessage(data)
        });

        channel.bind("twilio.new-contact", ({ data }) => {
          const payload = { message: null, contact: data }
          this.onNewMessage(payload)
        });

        channel.bind("twilio.sent-scheduled", ({ data, status }) => {
          const message = data;
          const messageIndex = this.conversations.findIndex(item => item.id === message.id);
          if (status === 'sent') {
            if (messageIndex !== -1) {
              this.conversations.splice(messageIndex, 1, message);
            }
          } else {
            this.conversations.splice(messageIndex, 1);
          }
        });
      }
    },

    onNewMessageById(data) {
      const { message_id, contact_id } = data;
      const param = {
        contactId: contact_id,
        messageId: message_id
      }
      this.$store
        .dispatch("chat/getConversation", param)
        .then((res) => {
          this.onNewMessage(res)
        });
    },

    onMessageUpdate(message) {
      const messageIndex = this.conversations.findIndex(item => item.id === message.id);
      if (messageIndex !== -1) {
        this.conversations.splice(messageIndex, 1, message);
      }
    },

    onNewMessage(data) {
      let addToList = false;
      const { message, contact } = data;
      if (this.selectedContact && contact.id == this.selectedContact.id) {
        if (message) {
          const messageIndex = this.conversations.findIndex(item => item.id === message.id);
          if (messageIndex !== -1) {
            this.conversations.splice(messageIndex, 1, message);
          } else {
            this.conversations = [...this.conversations, message]
          }
        }
        addToList = true;
      } else {
        addToList = true;
        if (this.installDepartmentEnabled) {
          if (this.selectedDepartment && this.selectedDepartment.id) {
            addToList =
              contact.departments.findIndex(
                (item) => item.id == this.selectedDepartment.id
              ) != -1;
          }
        }

        if (this.user.role == "BusinessUser") {
          if (contact.agent && contact.agent.id != this.user.id)
            addToList = false;
        }
      }

      if (addToList) {
        this.$store
          .dispatch("contact/fetch", contact.id)
          .then((res) => {
            let contactIndex = this.contacts.findIndex((item) => item.id == contact.id);

            if (contactIndex !== -1) {
              this.contacts.splice(contactIndex, 1);
            }

            this.addContactToList(res.data)

            if (message) {
              this.$store
                .dispatch("auth/getLiveData")
            }
            this.scrollMessagesToBottom();
          })
          .catch(() => { });
      }
    },

    addContactToList(newContact) {
      const contactExists = this.contacts.find(
        (item) => item.id == newContact.id
      );
      if (!contactExists) {
        if (this.statusFilter.id == "open") {
          this.contacts = [newContact, ...this.contacts];
        } else if (this.statusFilter.id == newContact.status) {
          this.contacts = [newContact, ...this.contacts];
        }
      }

      if (this.contacts.length == 1 && !this.selectedContact) {
        this.selectContact(this.contacts[0]);
      }
    },

    onChangeFilter(item) {
      this.contacts = [];
      this.conversations = [];
      this.selectedContact = null;
      this.contactPagination = { ...this.defaultContactPagination };
      this.getContacts();
    },

    onChangeTimeFilter() { },

    updateConversationStatus(status) {
      const data = {
        status: status,
      };

      this.loadingContacts = true;
      this.processingContact = true;
      this.$store
        .dispatch("chat/updateContact", {
          contactId: this.selectedContact.id,
          data: data,
        })
        .then((contact) => {
          this.$store
            .dispatch("auth/getLiveData")
          const index = this.contacts.findIndex(
            (item) => item.id == this.selectedContact.id
          );

          if (
            (this.statusFilter.id != "open" || ["deleted"].includes(status)) &&
            this.statusFilter.id != contact.status
          ) {
            this.contacts.splice(index, 1);
            this.conversations = []
            if (this.contacts && this.contacts.length) {
              const i = this.contacts[index] ? index : Object.keys(this.contacts)[this.contacts.length - 1]
              this.selectContact(this.contacts[i])
            } else {
              this.selectedContact = null
            }
          } else {
            this.contacts[index] = contact;
            this.selectedContact = contact;
          }

          this.loadingContacts = false;
          setTimeout(() => {
            this.processingContact = false;
          }, 500);
        })
        .catch(() => {
          this.loadingContacts = false;
          this.processingContact = false;
        });
    },

    getFullName(item) {
      return item.firstname + " " + item.lastname;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.activeTabIndex === 0 && this.newMessage || this.activeTabIndex === 1 && this.newEmail) {
      const answer = window.confirm('Are you sure you want to leave without sending?')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
};
</script>

<style lang="scss">
.message-page {
  .loader {
    padding: 0;
  }

  .atom-spinner {
    display: inline-block;
  }

  .border-top {
    border-top: 1px solid #efefef;
  }

  .border-bottom {
    border-bottom: 1px solid #efefef;
  }

  .border-left {
    border-left: 1px solid #efefef;
  }

  .border-right {
    border-right: 1px solid #efefef;
  }

  .install-select {
    input {
      border: none;
    }
  }

  .sms-time-container {
    text-align: center;
    margin-bottom: 15px;

    .sms-time {
      border: 1px solid #dee2e6;
      border-radius: 5px;
      padding: 5px 10px;
      display: inline-block;
      color: #666;
      font-size: 12px;
    }
  }

  .sms-seen-container {
    margin-top: 5px;

    .sms-seen {
      font-size: 12px;
      color: #6e6e6e;
      margin-right: 55px;
    }

    .delivered {
      .fa {
        color: $rb-green;
      }
    }

    .failed {
      .fa {
        color: $rb-red;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .header-row {
    label {
      font-size: 1rem;
    }
  }

  .profile-avatar-wrapper {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    position: absolute;

    .profile-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $rb-blue;
      border-radius: 50%;
      color: $white;
      width: 40px;
      height: 40px;
      left: 0px;
      font-size: 14px;
      font-weight: bold;

      .avatar-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .google-icon {
      position: absolute;
      right: 0px;
      bottom: -4px;

      img {
        width: 18px;
        padding: 3px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }

  .widget {
    margin-bottom: 0;

    .widget-header {
      border-bottom: 0;
    }

    .widget-body {
      padding: 0;
      position: relative;
    }

    .search-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;

      .with-icon-left {
        flex: auto;
        margin-bottom: 0;
        min-height: auto;

        input {
          border: 1px solid #dee2e6;
          background-color: #fff;
          padding: 4px 0;
          padding-left: 32px;
          min-width: 300px;
        }

        .input-icon {
          top: 8px;
          left: 9px;
          color: $rb-blue;
        }
      }

      .edit-div {
        display: none;
        flex: 0 0 30px;
        text-align: right;
        font-size: 22px;
      }
    }

    .sms-wrapper {
      display: flex;
      min-height: calc(#{$widget-viewport-height} - 48px);
      max-height: calc(#{$widget-viewport-height} - 48px);

      .layout.has-notification & {
        min-height: calc(#{$widget-viewport-height} - #{$top-notification-height} - 48px);
        max-height: calc(#{$widget-viewport-height} - #{$top-notification-height} - 48px);
      }

      .sub-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-height: 64px;
        min-height: 64px;
      }

      &__left {
        flex: 0 0 350px;
        display: flex;
        flex-direction: column;

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
          flex: 0 0 280px;
        }

        @media (max-width: 1024px) {
          flex: 0 0 280px;
        }

        .sub-header {
          padding-right: 10px !important;
        }

        .filter-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 20px !important;
          padding-right: 10px !important;
          min-height: 64px;
          max-height: 64px;
        }

        .contact-list-wrapper {
          padding: 0px 0;
          overflow-y: auto;
          flex: 1 1 auto;

          .contact-item {
            cursor: pointer;
            padding: 10px 10px 10px 15px;
            border-bottom: 1px solid #efefef;
            position: relative;
            min-height: 60px;
            display: flex;

            .profile-avatar-wrapper {
              margin-right: 10px;
              position: relative;
            }

            .profile-content-wrapper {
              flex: 1 0 auto;
            }

            &__header {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              padding: 0;

              span.alert-span {
                font-size: 20px;
              }
            }

            .message {
              font-size: 13px;
            }

            &::before {
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 50%;
              // background: $rb-gray;
              position: absolute;
              top: 1.8rem;
              left: 0.4rem;
            }

            &:hover,
            &.selected {
              background-color: $rb-blue !important;
              color: $white;

              &.unread {
                &::before {
                  background-color: $white !important;
                }
              }

              span.alert-span i {
                color: $white !important;
              }

              .profile-avatar {
                background-color: #fff;
                color: $rb-blue;
              }
            }

            &.unread {
              &::before {
                background-color: $rb-blue;
              }
            }

            &:nth-child(even) {
              background-color: $rb-lgray;
            }
          }

          .list-blank {
            text-align: center;
            margin-top: 20px;
          }
        }
      }

      &__right {
        flex: auto;
        display: flex;
        flex-direction: column;

        .sub-header {
          padding-left: 20px !important;
          padding-right: 20px !important;
          justify-content: space-between;

          .action-wrapper {
            ul {
              list-style: none;
              margin: 0;
              padding: 0;

              li {
                display: inline-block;

                >div,
                >a {
                  padding: 0 10px;
                }

                span.trigger-span {
                  font-size: 21px;
                }
              }
            }
          }
        }

        .sms-content {
          padding: 10px 20px;
          overflow-y: auto;
          flex: 1 1 auto;

          .sms-item {
            position: relative;
            padding: 5px 0;
            margin-top: 5px;
            text-align: right;

            &.link-opened {
              .conversation-wrapper {
                border-color: #669f00;
              }
            }

            .conversation-wrapper {
              position: relative;
              background-color: #d8ecff;
              border: 1px solid #b0d5ff;
              border-radius: 5px;
              padding: 8px 20px;
              max-width: 50%;
              cursor: pointer;
              margin-left: auto;
              display: inline-block;
              margin-right: 55px;
              text-align: left;

              @media (max-width: 1380px) {
                max-width: 75%;
              }
            }

            .audio-wrapper>div:nth-child(2) {
              display: none;
            }

            .media-wrapper {
              &.no-padding {
                padding: 10;
                margin-top: 0;
                margin-bottom: 0;
              }

              .file-wrapper {
                display: flex;
                align-items: center;
              }

              img {
                border-radius: 10px;
                max-width: 300px;
              }

              i {
                font-size: 25px;
                margin-right: 10px;
              }
            }

            span.message {
              color: $darkest-gray;
              text-align: left;
              font-size: 15px;
              word-break: break-word;
              cursor: pointer;
              white-space: pre-line;

              p {
                margin-bottom: 0;
              }
            }

            span.timestamp {
              display: block;
              font-size: 14px;
              line-height: 30px;
              padding-right: 10px;
            }

            .profile-avatar-wrapper {
              &.to {
                left: auto;
                right: 0px;

                .profile-avatar {
                  background-color: $rb-green;
                }
              }
            }

            &.from {
              align-items: flex-start;
              text-align: left;

              .conversation-wrapper {
                background-color: #f5f5f5;
                border: 1px solid #dfdfdf;
                margin-left: 55px;
              }

              span.message {
                color: $darkest-gray;
                cursor: pointer;
              }

              span.timestamp {
                padding-left: 70px;
              }

              .sms-seen-container {
                margin-left: 55px;
              }
            }

            &.broadcast {
              .conversation-wrapper {
                background-color: $rb-green;
                border: 1px solid $rb-green;
              }

              span.message,
              .file-wrapper {
                color: $white;

                a {
                  color: $white;
                }
              }
            }

            &.email {
              .conversation-wrapper {
                background-color: $rb-blue;
                border: 1px solid $rb-blue;
              }

              span.message {
                color: #fff;
              }
            }

            &.schedule {
              .conversation-wrapper {
                background-color: $rb-yellow;
                border: 1px solid darken($rb-yellow, 10%);
              }

              span.message {
                // color: $white;
              }
            }

            span.schedule_info {
              font-size: 12px;

              svg {
                width: 12px;
                height: 12px;
              }

              .cancel-link {
                text-decoration: underline;

                &:hover {
                  font-weight: bold;
                }
              }
            }
          }
        }

        .closed-span {
          background-color: #f5f5f5;
        }

        .sms-send-wrapper {
          background-color: #f5f5f5;

          .text-tabs.tabs {
            >.card-header {
              display: none;
            }

            &.show-header {
              .card-header {
                display: block;
              }
            }

            >.tab-content {
              min-height: 100px;

              .tab-pane {
                padding: 3px;
              }
            }
          }
        }

        .contact-div {
          .name {
            display: block;
            font-size: 17px;
            font-weight: bold;
          }

          .phone {
            display: block;
            font-weight: normal;
            font-size: 13px;
          }
        }
      }

      .sms-contact-detail {
        max-width: 280px;
        min-width: 280px;
        display: flex;
        flex-direction: column;

        .contact-edit-wrapper {
          position: relative;

          .atom-spinner {
            position: absolute;
            right: 5px;
            top: 6px;
          }
        }

        .sub-header {
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            color: $rb-blue;
          }

          a {
            padding: 0 10px;
          }
        }

        .contact-detail-wrapper {
          padding: 0px;
          flex: 1 1 auto;
          overflow-y: auto;

          .tabs {
            display: flex;
            flex-direction: column;

            .tab-content {
              padding-top: 0.5rem;
            }

            .tab-pane {
              padding: 10px;
            }

            .card-header {
              display: none;
            }

            &.show-header {
              .card-header {
                display: block;
              }
            }
          }

          span.detail-span {
            display: block;
            margin-bottom: 15px;
            width: 100%;
          }

          .contact-info-wrapper {
            input,
            textarea {
              border: none;
              background-color: #f1f1f1;
              padding: 5px 10px;
              outline: none;
              box-shadow: none;
              margin-bottom: 15px;
              width: 100%;
            }
          }

          .contact-files-wrapper {
            .contact-file-item {
              margin-bottom: 10px;

              a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              .preview {
                width: 50px;
                margin-right: 10px;
              }

              i {
                width: 50px;
                font-size: 30px;
                margin-right: 10px;
                text-align: center;
              }
            }
          }
        }
      }

      .sub-header {
        padding: 0px 10px;
        background-color: #f5f5f5;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.top-from-text {
  padding: 5px 25px;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  color: #3578c6;
  min-height: 42px;
  max-height: 42px;

  span {
    cursor: pointer;
  }
}

.checkbox-label {
  --primaryColor: #3578c6;
}

.custom-checkbox-wrapper .checkbox-label .checkbox-custom {
  border-color: $text-gray;
  top: 4px;
  height: 17px;
  width: 17px;
}

.custom-checkbox-wrapper .checkbox-label input:checked~.checkbox-custom::after {
  left: 5px;
  top: 0px;
}

.tag-wrapper {
  span.add-tag-btn {
    display: inline-block;
    border: 1px dashed #ccc;
    border-radius: 5px;
    padding: 2px 5px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      border-color: #3578c6;
      color: #3578c6;
    }
  }

  span.tag {
    cursor: pointer;
    background-color: #3578c6;
    color: #fff;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 0.85em;
    display: block;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 5px;

    i {
      background-color: #325f94;
      padding: 2px 3px;
      border-radius: 50%;
    }

    &:hover i {
      background-color: #15457d;
    }
  }
}

.automation-icon {
  position: absolute;
  font-size: 11px;
  left: -13px;
  top: -5px;
  width: 23px;
  height: 23px;
  background-color: #1c76d1;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
