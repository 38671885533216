<template>
  <div class="inbox-task-tab">
    <div class="d-block text-center mb-2">
      <a href="javascript:;" class="btn btn-sm btn-primary new-task-btn" @click="onNewTaskButton()"><i class="fa fa-plus"></i> Add Task</a>
    </div>
    <div class="text-center my-5" v-if="loadingContactTasks">
      <atom-spinner slot="loading" :animation-duration="1500" :size="40" color="#3578c6" />
    </div>
    <div v-else class="d-block task-list-wrapper">
      <template v-if="contactTasks && contactTasks.length">
        <ul class="task-list-ul">
          <li class="mb-1" v-for="(task, key) in contactTasks" :key="key">
            <a class="task-name" href="javascript:;" v-b-tooltip.hover v-b-tooltip.top :title="task.description | prune(150)" @click="onTaskDetailClick(task)">{{ task.name | prune(60)
            }}</a>
            <span class="d-block task-icon-wrapper">
              <span v-if="getStatusIcon(task)" class="task-status-icon pr-1">
                <i :class="getStatusIcon(task)" v-b-tooltip.hover v-b-tooltip.top :title="task.status === 1 ? 'Attention' : task.status_str"></i>
              </span>
              <a class="task-delete-icon" href="javascript:;" @click="onTaskDeleteClick(task)"><i
                  class="fa fa-times"></i></a>
            </span>
          </li>
        </ul>
      </template>
    </div>
    <!-- Tasks Model Starts   -->
    <vuestic-modal :isOpen="isOpenModalTask" @ok="closeModalCreateTask" @cancel="closeModalCreateTask"
      okText="Create" :cancelShown="false" :okShown="false">
      <span slot="title">{{ isEdit ? 'Update Task' : 'Create a Task' }}</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="row">
            <div v-if="isEdit" class="col-md-12 mb-3">
              Status: <b>{{ newTaskForm.status_str}} </b>
            </div>
            <div class="col-md-12">
              <text-input name="Name" v-model="newTaskForm.name" label="Name" validate="required" />
            </div>
            <div class="col-md-12 mt-4">
              <textarea-input max-length="1200" name="Description" :rows="3" :hideLength="true"
                v-model="newTaskForm.description" label="Description" validate="required" />
            </div>
            <div class="col-md-12 mt-4 d-flex justify-content-between">
              <div class="form-group" style="width: 50%;">
                <div class="input-group">
                  <label class="control-label">Date</label>
                  <vuestic-date-picker id="scheduled_at_day_picker_task" v-model="newTaskForm.date"
                    :config="datePickerConfig" />
                  <i class="bar"></i>
                </div>
              </div>
              <div class="form-group ml-2" style="width: 50%;">
                <div class="input-group">
                  <label class="control-label">Time</label>
                  <vuestic-date-picker id="scheduled_at_time_picker_tasl" v-model="newTaskForm.time"
                    :config="timePickerConfig" />
                  <i class="bar"></i>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <multiselect v-model="timezoneScheduled" :options="timezones" :multiple="false" :close-on-select="true"
                :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone" label="label"
                track-by="label" :preselect-first="true" :show-labels="false">
              </multiselect>
            </div>
            <div v-if="isEdit" class="col-md-12 mt-4">
              <div class="custom-checkbox-wrapper d-inline-block">
                <label class="checkbox-label">
                  <input type="checkbox" id="complete-checkbox" v-model="markCompleted" />
                  <span class="checkbox-custom"></span>
                </label>
              </div>
              <span class="d-inline-block ml-2" style="cursor: pointer" @click="onToggleCheckbox('completed')">Mark it as completed</span>
            </div>
            <div v-if="isEdit" class="col-md-12 mt-3">
              <div class="custom-checkbox-wrapper d-inline-block">
                <label class="checkbox-label">
                  <input type="checkbox" id="close-checkbox" v-model="markClosed" />
                  <span class="checkbox-custom"></span>
                </label>
              </div>
              <span class="d-inline-block ml-2" style="cursor: pointer" @click="onToggleCheckbox('closed')">Mark it as closed</span>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button type="submit" class="btn btn-primary" :disabled="processing || invalid && dirty">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                <span v-else>{{ isEdit ? 'Update' : 'Create' }}</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalDeleteTask" :okDisabled="processingTask" :processing="processingTask"
      @ok="handleDeleteTask" @cancel="isOpenModalDeleteTask = false" okText="Delete" okClass="btn btn-danger"
      cancelText="Cancel" :closeOnOk="false">
      <span slot="title" class="text-danger">Delete this Task?</span>
      <p>Are you sure you want to delete this task? This can not be undone.</p>
    </vuestic-modal>
    <!--  Task Model Ends   -->
  </div>
</template>
    
<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
    timezone: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      datePickerConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
      loadingContactTasks: false,
      processing: false,
      processingTask: false,
      isOpenModalTask: false,
      isOpenModalDeleteTask: false,
      contactTasks: [],
      selectedTask: null,
      newTaskForm: {},
      timezoneScheduled: undefined,
      newTaskDefault: {
        name: "",
        description: "",
        time: moment().add(1, 'hours').format("hh:mm a"),
        date: moment().format("YYYY-MM-DD"),
        timezone: "",
        send_msg: false,
      },
      markCompleted: false,
      markClosed: false,
    };
  },

  computed: {
    timezones() {
      return this.$store.getters["timeZones"];
    },

    isEdit() {
      return this.newTaskForm && this.newTaskForm.id
    }
  },

  watch: {
    contactId: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.getTasks();
        }
      },
    },

    timezone: {
      handler: function (newValue, oldValue) {
        if (newValue) {
          // this.newTaskDefault.timezone = newValue
        }
      },
    }
  },

  mounted() {
    if (this.contactId) {
      this.getTasks();
    }
    let localTimezone = this.timezones.find(
      (item) => item.value === moment.tz.guess()
    );
    this.newTaskDefault.timezone = localTimezone ? localTimezone.value : undefined
  },

  methods: {
    getStatusIcon(item) {
      let icon = ''
      switch (item.status) {
        case 4:
          icon = 'danger fa fa-times-circle-o';
          break;
        case 3:
          icon = 'warning fa fa-clock-o';
          break;
        case 2:
          icon = 'success fa fa-check-circle-o';
          break;
        case 1:
        default:
          break;
      }
      if (!icon && item.attention) {
        icon = 'danger fa fa-exclamation-circle';
      }
      return icon
    },
    onToggleCheckbox(type) {
      if (type === 'completed') {
        this.markCompleted = !this.markCompleted
        this.markClosed = false
      } else {
        this.markCompleted = false
        this.markClosed = !this.markClosed
      }
    },
    getTasks() {
      const params = {
        contact_id: this.contactId,
        // status: 1, // todo
      };
      this.loadingContactTasks = true;
      this.$store
        .dispatch("task/all", params)
        .then((res) => {
          this.contactTasks = res
          this.loadingContactTasks = false;
        })
        .catch(() => {
          this.contactTasks = [];
          this.loadingContactTasks = false;
        });
    },

    onSubmit() {
      if (this.isEdit) {
        this.updateTask()
      } else {
        this.createTask()
      }
    },

    createTask() {
      this.processing = true;
      const submitData = {
        ...this.newTaskForm,
        timezone: this.timezoneScheduled,
        contact_id: this.contactId,
      };

      this.$store
        .dispatch("task/create", submitData)
        .then(() => {
          // Task created successfully
          this.processing = false;
          this.isOpenModalTask = false;
          this.getTasks()
        })
        .catch((error) => {
          // Handle the error, e.g., show an error message
          console.error(error);
          this.processing = false;
        });
    },
    
    updateTask() {
      this.processing = true;
      const submitData = {
        id : this.newTaskForm.id,
        data: {
          ...this.newTaskForm,
          timezone: this.timezoneScheduled,
          contact_id: this.contactId,
          completed: this.markCompleted,
          closed: this.markClosed,
        }
      };

      this.$store
        .dispatch("task/update", submitData)
        .then(() => {
          // Task update successfully
          this.processing = false;
          this.isOpenModalTask = false;
          this.getTasks()
        })
        .catch((error) => {
          // Handle the error, e.g., show an error message
          console.error(error);
          this.processing = false;
        });
    },

    onTaskDetailClick(task) {
      this.newTaskForm = {
        ...this.newTaskDefault,
        ...task
      }

      this.markClosed = task.status === 4
      this.markCompleted = task.status === 2
      this.timezoneScheduled = this.timezones.find(
        (item) => item.value === this.newTaskForm.timezone
      );
      this.isOpenModalTask = true
    },

    onTaskDeleteClick(task) {
      this.selectedTask = task
      this.isOpenModalDeleteTask = true
    },

    onNewTaskButton() {
      this.newTaskForm = {
        ...this.newTaskDefault,
      }
      this.timezoneScheduled = this.timezones.find(
        (item) => item.value === this.newTaskForm.timezone
      );
      this.isOpenModalTask = true;
    },

    closeModalCreateTask() {
      this.isOpenModalTask = false;
    },

    handleDeleteTask() {
      if (!this.selectedTask)
        return
      this.processingTask = true
      this.$store
        .dispatch("task/delete", this.selectedTask.id)
        .then(() => {
          // Task created successfully
          this.processingTask = false;
          this.isOpenModalDeleteTask = false;
          this.getTasks()
        })
        .catch((error) => {
          // Handle the error, e.g., show an error message
          console.error(error);
          this.processingTask = false;
        });
    },
  },
};
</script>
    
<style lang="scss">
.inbox-task-tab {
  .new-task-btn {
    padding: 6px 10px;
  }
  .task-list-ul {
    padding-left: 0px;
    margin-top: 10px;
    list-style: none;
    li {
      position: relative;
      padding-bottom: 5px;
      .task-name {
        display: block;
        width: 100%;
        padding-right: 45px;
        background-color: #f5f5f5;

        &:hover {
          color: $rb-blue;
        }
      }

      .task-icon-wrapper {
        position: absolute;
        top: 0px;
        right: 10px;
        .task-status-icon {
          .danger {
            color: #e34a4a;
          }
          .warning {
            color: #ceb42e;
          }
          .success {
            color: #31ab52;
          }
        }
        .task-delete-icon {
          &:hover {
            color: $rb-red;
          }
        }
      }
    }
  }
}
</style>
    